import React from 'react'
import Header from '../Components/Header'
import HeroSection from '../Components/Founder/Hero'
import AboutFounder from '../Components/Founder/AboutFounder'
import Footer from '../Components/Footer'

const Founder = () => {
  return (
    <div>
        <Header/>
        <HeroSection/>
        <AboutFounder/>
        <Footer/>
    </div>
  )
}

export default Founder