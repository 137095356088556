import React from 'react';
import { Box, Typography, Container, AppBar, Toolbar, Button, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../Components/Header';
import SectionTop from '../Components/About/SectionTop';
import Values from '../Components/About/Values';
import Mission from '../Components/About/Mission';
import Vision from '../Components/About/Vision';
import Certifications from '../Components/Certifications';
import Footer from '../Components/Footer';

const About = () => {
  return (
   <div>
    <Header/>
    <SectionTop/>
    <Values/>
    <Mission/>
    <Vision/>
    <Certifications/>
    <Footer/>
   </div>
  );
};

export default About;