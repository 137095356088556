import React from "react";
import { Box, Typography, Container, Grid, Paper } from "@mui/material";

const ValueCard = ({ color, title, description }) => (
  <Paper
    elevation={3}
    sx={{
      backgroundColor: color,
      borderRadius: "16px",
      height: "100%",
      p: 3,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      transition: "transform 0.3s ease-in-out",
      "&:hover": {
        transform: "scale(1.05)",
      },
    }}
  >
    <Typography
      variant="h5"
      component="h3"
      align="center"
      sx={{ mb: 2, fontWeight: "bold" }}
    >
      {title}
    </Typography>
    <Typography variant="body1" align="center">
      {description}
    </Typography>
  </Paper>
);

const Values = () => {
  const values = [
    {
      color: "#E8F5E9",
      title: "Sustainability",
      description:
        "We prioritize eco-friendly practices in all aspects of our business.",
    },
    {
      color: "#FFF3E0",
      title: "Innovation",
      description:
        "We constantly pursue cutting-edge research to improve our products.",
    },
    {
      color: "#E3F2FD",
      title: "Community",
      description:
        "We foster a supportive community of health-conscious individuals.",
    },
  ];

  return (
    <Container sx={{ my: 8 }}>
      <Typography
        variant="h4"
        component="h2"
        align="center"
        sx={{
          fontWeight: "bold",
          textTransform: "uppercase",
          borderBottom: "2px solid #000",
          pb: 1,
          mb: 4,
        }}
      >
        Our Values
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {values.map((value, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ValueCard {...value} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Values;
