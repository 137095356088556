import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Link, CircularProgress } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from 'react-router-dom';

const CategoryItem = ({ id, name, category_image, color }) => (
  <RouterLink to={`/shop/${id}`} style={{ textDecoration: 'none' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 1 }}>
      <Box
        sx={{
          width: { xs: 70, sm: 120, md: 160 },
          height: { xs: 70, sm: 120, md: 160 },
          borderRadius: '50%',
          backgroundColor: color || '#F6BCEE', // Default color if none provided
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 1,
        }}
      >
        {category_image ? (
          <img src={category_image} alt={name} style={{ maxWidth: '80%', maxHeight: '80%', objectFit: 'contain' }} />
        ) : (
          <Typography variant="h4" color="text.secondary">{name.charAt(0)}</Typography>
        )}
      </Box>
      <Typography variant="caption" align="center" color="text.primary" sx={{fontSize:'1rem', fontWeight:'600',}}>
        {name}
      </Typography>
    </Box>
  </RouterLink>
);

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch('https://hmzafuel.com/APIs/categories.php');
      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }
      const data = await response.json();
      //console.log("Fetched data:", data); // Debugging log
      // Filter out categories with parent_id (to show only top-level categories)
      const topLevelCategories = data.filter(category => category.parent_id === null);
      setCategories(topLevelCategories);
      setLoading(false);
    } catch (err) {
      console.error("Fetch error:", err); // Debugging log
      setError(err.message);
      setLoading(false);
    }
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography color="error" align="center">{error}</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Slider {...settings}>
          {categories.map((category) => (
            <CategoryItem key={category.id} {...category} />
          ))}
        </Slider>
      </Box>
    </Container>
  );
};

export default Categories;
