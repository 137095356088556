import React from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";

// ReviewCard component
const ReviewCard = ({ title, content, author }) => (
  <Card
    sx={{
      height: "100%",
      borderRadius: "16px",
      boxShadow: "none",
      backgroundColor: "#FFF9F0", // Light cream color for the cards
    }}
  >
    <CardContent>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        align="center"
        fontWeight="bold"
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        paragraph
        align="center"
      >
        "{content}"
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        {author}
      </Typography>
    </CardContent>
  </Card>
);

// Reviews data
const reviewsData = [
  {
    title: "GREAT TASTE AND FLAVOUR",
    content:
      "This protein supplement has significantly enhanced my workouts and recovery, highly recommend it!",
    author: "Seff Thomas",
  },
  {
    title: "GREAT TASTE AND FLAVOUR",
    content:
      "This protein supplement has significantly enhanced my workouts and recovery, highly recommend it!",
    author: "Seff Thomas",
  },
  {
    title: "GREAT TASTE AND FLAVOUR",
    content:
      "This protein supplement has significantly enhanced my workouts and recovery, highly recommend it!",
    author: "Seff Thomas",
  },
  {
    title: "GREAT TASTE AND FLAVOUR",
    content:
      "This protein supplement has significantly enhanced my workouts and recovery, highly recommend it!",
    author: "Seff Thomas",
  },
  {
    title: "GREAT TASTE AND FLAVOUR",
    content:
      "This protein supplement has significantly enhanced my workouts and recovery, highly recommend it!",
    author: "Seff Thomas",
  },
  {
    title: "GREAT TASTE AND FLAVOUR",
    content:
      "This protein supplement has significantly enhanced my workouts and recovery, highly recommend it!",
    author: "Seff Thomas",
  },
];

// Main Reviews component
const Reviews = () => {
  return (
    <Box sx={{ backgroundColor: "#FFC107", padding: 4, mt:4 }}>
      <Typography
        variant="h4"
        component="h2"
        align="center"
        sx={{ mb: 4, fontWeight: "bold" , textTransform: "uppercase", color:'#F00'}}
      >
        REVIEWS
      </Typography>
      <Grid container spacing={3}>
        {reviewsData.map((review, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ReviewCard {...review} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Reviews;
