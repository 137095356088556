import React from 'react';
import { Box, Typography, Container, Grid, Paper } from '@mui/material';

const Vision = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            sx={{
              bgcolor: '#FFF5E6', // Light beige color to match the image
              height: '100%',
              minHeight: 300,
              border: '1px solid #4AA7FF', // Light blue border
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="body1" color="text.secondary">
              Image or video content will go here
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography
              variant="h4"
              component="h2"
              sx={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                borderBottom: '2px solid #000',
                pb: 1,
                display: 'inline-block',
              }}
            >
              Our Vision
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            {/* Add your vision content here */}
            <Typography variant="body1">
              Our vision is to revolutionize the supplement industry by providing
              innovative, high-quality products that enhance the well-being of our customers.
              We aim to be at the forefront of nutritional science, combining nature's
              wisdom with cutting-edge research to create supplements that truly make a difference.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Vision;