import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';

const InfoBar = () => {
  const infoItems = [
    {
      icon: <SecurityIcon fontSize="large" />,
      title: "Secure Payment",
      subtitle: "100% Secure Payment Gateway"
    },
    {
      icon: <LocalShippingIcon fontSize="large" />,
      title: "Safe Shipping",
      subtitle: "100% Secure"
    },
    {
      icon: <AssignmentReturnIcon fontSize="large" />,
      title: "Return Policy",
      subtitle: ""
    }
  ];

  return (
    <Box sx={{ backgroundColor: 'var(--main-yello, #FFD300)', py: 3, mt: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center">
          {infoItems.map((item, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Box 
                display="flex" 
                alignItems="center" 
                justifyContent={{ xs: 'flex-start', sm: 'center' }}
                sx={{ flexDirection: { xs: 'row', sm: 'column' }, textAlign: { xs: 'left', sm: 'center' } }}
              >
                <Box
                  sx={{
                    width: { xs: 40, sm: 60 },
                    height: { xs: 40, sm: 60 },
                    borderRadius: '50%',
                    border: '2px solid white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mr: { xs: 2, sm: 0 },
                    mb: { xs: 0, sm: 2 },
                    color: 'white'
                  }}
                >
                  {item.icon}
                </Box>
                <Box>
                  <Typography variant="h6" component="div" sx={{ 
                    color: 'white', 
                    fontWeight: 'bold',
                    fontSize: { xs: '1rem', sm: '1.25rem' }
                  }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ 
                    color: 'white',
                    fontSize: { xs: '0.875rem', sm: '1rem' }
                  }}>
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default InfoBar;