import React from 'react';
import {
  Typography,
  Box,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import Footer from '../Components/Footer';
import Header from '../Components/Header';

const FullWidthPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: 0,
  borderRadius: 0,
  backgroundColor: '#f9f9f9',
  minHeight: '100vh',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '1200px',
  margin: '0 auto',
  padding: theme.spacing(0, 2),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
}));

const SubSectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  fontWeight: 'bold',
}));

const PrivacyPolicy = () => {
  return (
    <>
    <Header/>
    <FullWidthPaper elevation={0}>
      <ContentWrapper>
        <Typography variant="h3" gutterBottom align="center" sx={{ fontWeight: 'bold', my: 4 }}>
          Privacy Policy
        </Typography>
        <Typography variant="subtitle1" paragraph align="center">
          Last updated: July 4, 2024
        </Typography>
        <Divider sx={{ mb: 4 }} />
        
        <Typography paragraph>
          At NutriLife Supplements, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.nutrilifesupplements.com or make a purchase from us.
        </Typography>
        
        <SectionTitle variant="h5">1. Information We Collect</SectionTitle>
        <Typography paragraph>
          We collect personal information that you voluntarily provide to us when you:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="- Register on our website" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Place an order" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Subscribe to our newsletter" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Participate in a survey or contest" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Contact us for customer support" />
          </ListItem>
        </List>
        <Typography paragraph>
          This information may include:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="- Name" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Email address" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Mailing address" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Phone number" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Payment information (credit card numbers, etc.)" />
          </ListItem>
        </List>
        
        <SectionTitle variant="h5">2. How We Use Your Information</SectionTitle>
        <Typography paragraph>
          We use the information we collect for various purposes, including:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="- Processing and fulfilling your orders" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Communicating with you about your orders, products, and services" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Providing customer support" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Sending you marketing communications (with your consent)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Improving our website and products" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Conducting research and analysis" />
          </ListItem>
        </List>
        
        <SectionTitle variant="h5">3. Information Sharing and Disclosure</SectionTitle>
        <Typography paragraph>
          We may share your personal information with:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="- Service providers who assist us in operating our website, conducting our business, or servicing you" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Law enforcement or other governmental agencies, in response to a subpoena, court order, or similar legal process" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Third parties to whom we sell, transfer, or merge parts of our business or our assets" />
          </ListItem>
        </List>
        <Typography paragraph>
          We do not sell, rent, or trade your personal information to third parties for their marketing purposes.
        </Typography>
        
        <SectionTitle variant="h5">4. Data Security</SectionTitle>
        <Typography paragraph>
          We implement appropriate technical and organizational security measures to protect your personal information from accidental loss, unauthorized access, use, alteration, and disclosure. However, please note that no method of transmission over the internet or electronic storage is 100% secure.
        </Typography>
        
        <SectionTitle variant="h5">5. Cookies and Tracking Technologies</SectionTitle>
        <Typography paragraph>
          We use cookies and similar tracking technologies to track activity on our website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our website.
        </Typography>
        
        <SectionTitle variant="h5">6. Third-Party Links</SectionTitle>
        <Typography paragraph>
          Our website may contain links to third-party websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
        </Typography>
        
        <SectionTitle variant="h5">7. Children's Privacy</SectionTitle>
        <Typography paragraph>
          Our website is not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us.
        </Typography>
        
        <SectionTitle variant="h5">8. Your Rights and Choices</SectionTitle>
        <Typography paragraph>
          You have the right to:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="- Access, correct, or delete your personal information" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Object to or restrict our processing of your personal information" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Request a portable copy of your personal information" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Opt-out of marketing communications" />
          </ListItem>
        </List>
        <Typography paragraph>
          To exercise these rights, please contact us using the information provided at the end of this policy.
        </Typography>
        
        <SectionTitle variant="h5">9. Changes to This Privacy Policy</SectionTitle>
        <Typography paragraph>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this policy.
        </Typography>
        
        <SectionTitle variant="h5">10. Contact Us</SectionTitle>
        <Typography paragraph>
          If you have any questions about this Privacy Policy, please contact us at:
        </Typography>
        <Typography>
          NutriLife Supplements, Inc.<br />
          1234 Wellness Avenue<br />
          Health City, HC 12345<br />
          United States<br />
          Email: privacy@nutrilifesupplements.com<br />
          Phone: (555) 123-4567
        </Typography>
      </ContentWrapper>
    </FullWidthPaper>
    <Footer/>
    </>
  );
};

export default PrivacyPolicy;