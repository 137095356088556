import React, { useEffect, useState } from "react";
import {
  Drawer,
  Typography,
  Box,
  IconButton,
  Button,
  Divider,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useCart } from "../CartContext";
import CheckoutModal from "./CheckoutModal";

// Sample product data (replace with your actual data source)
const productData = [
  {
    id: 1,
    name: "Dark Chocolate Peanut Butter 400g",
    price: 500,
    image: "/img/image-33.png",
    description: "20g Protein | No Added Sugar",
  },
  {
    id: 2,
    name: "Dark Chocolate Peanut Butter 400g",
    price: 300,
    image: "/img/image-34.png",
    description: "20g Protein | No Added Sugar",
  },
];

const saveCartToLocalStorage = (cart) => {
  localStorage.setItem('cart', JSON.stringify(cart));
};

const Cart = ({ open, onClose }) => {
  const { cartItems, removeFromCart, updateQuantity, addToCart } = useCart();
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  
  useEffect(() => {
    saveCartToLocalStorage(cartItems);
  }, [cartItems]);
  
  const total = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
  const handleCheckout = () => {
    setIsCheckoutOpen(true);
  };

  const isCartEmpty = cartItems.length === 0;

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: "100%", maxWidth: 400, bgcolor: "#fff" },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Typography variant="h6" fontWeight="bold">
              Cart
            </Typography>
            <IconButton
              onClick={onClose}
              size="small"
              sx={{ bgcolor: "var(--main-yello, #FFD300)", "&:hover": { bgcolor: "var(--Final-red, #F00)" } }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Divider sx={{ mb: 2 }} />

          <Typography
            variant="body2"
            sx={{
              mb: 2,
              fontSize: "0.75rem",
              textAlign: "center",
              color: "#39AFD3",
            }}
          >
            Minimum Order Value 500 | FREE DELIVERY And COD Available On Order
            Above 399
          </Typography>

          <Divider sx={{ mb: 2 }} />

          {isCartEmpty ? (
            <Box sx={{ textAlign: 'center', my: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Your cart is empty
              </Typography>
              <Link href="/shop" underline="none">
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "var(--main-yello, #FFD300)",
                    color: "black",
                    "&:hover": { bgcolor: "var(--Final-red, #F00)" },
                  }}
                >
                  Go to Shop
                </Button>
              </Link>
            </Box>
          ) : (
            <>
              {cartItems.map((item) => (
                <Box key={item.id} sx={{ display: "flex", mb: 2 }}>
                  <Box
                    sx={{
                      width: 80,
                      height: 80,
                      bgcolor: "#FFF9C4",
                      mr: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {item.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mt: 1 }}
                    >
                      <Box sx={{ display: "flex", border: "1px solid #39AFD3" }}>
                        <IconButton size="small" onClick={() => updateQuantity(item.id, item.quantity - 1)}>
                          <RemoveIcon fontSize="small" />
                        </IconButton>
                        <Typography sx={{ mx: 1, alignSelf: "center" }}>{item.quantity}</Typography>
                        <IconButton size="small" onClick={() => updateQuantity(item.id, item.quantity + 1)}>
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </Box>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.price * item.quantity)}
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton size="small" sx={{ alignSelf: "flex-start" }} onClick={() => removeFromCart(item.id)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              ))}

              <Divider sx={{ mb: 2 }} />

              <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                You may also like
              </Typography>
              {productData.filter(product => !cartItems.some(item => item.id === product.id)).map((product) => (
                <Box
                  key={product.id}
                  sx={{
                    bgcolor: "#f5f5f5",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      width: 80,
                      height: 80,
                      bgcolor: "#FFF9C4",
                      mr: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={product.image}
                      alt={product.name}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle2">
                      {product.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {product.description}
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 0.5 }}>
                      Rs.{product.price}
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ mr: 1, borderColor: "#39AFD3", color: "#000" }}
                      >
                        Single bar
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => addToCart(product)}
                        sx={{
                          bgcolor: "var(--main-yello, #FFD300)",
                          color: "white",
                          "&:hover": { bgcolor: "var(--Final-red, #F00)" },
                        }}
                      >
                        Add
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ))}

              <Button
                variant="contained"
                fullWidth
                onClick={handleCheckout}
                sx={{
                  mt: 2,
                  bgcolor: "var(--main-yello, #FFD300)",
                  color: "black",
                  "&:hover": { bgcolor: "var(--Final-red, #F00)" },
                  py: 1.5,
                  fontSize: "1.1rem",
                }}
              >
                Checkout: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(total)}
              </Button>
            </>
          )}
          
          <Button variant="text" fullWidth sx={{ mt: 1, color: "black" }} onClick={onClose}>
            Continue shopping
          </Button>
        </Box>
      </Drawer>
      <CheckoutModal open={isCheckoutOpen} onClose={() => setIsCheckoutOpen(false)} />
    </>
  );
};

export default Cart;