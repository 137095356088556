import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
  IconButton,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  Phone,
  Email,
  LocationOn,
  Facebook,
  Twitter,
  Instagram,
  LinkedIn
} from '@mui/icons-material';

const ContactPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        backgroundImage: 'url("https://mejona.in/assets/img/hero/woman_about.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 4, backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
          <Typography variant="h3" align="center" gutterBottom>
            Contact <span style={{ color: theme.palette.primary.main }}>Us</span>
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            We'd love to hear from you. Feel free to reach out using the form below or through our contact details.
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box component="form" noValidate autoComplete="off">
                <TextField fullWidth label="Name" margin="normal" required />
                <TextField fullWidth label="Email" margin="normal" required type="email" />
                <TextField fullWidth label="Subject" margin="normal" required />
                <TextField
                  fullWidth
                  label="Message"
                  margin="normal"
                  required
                  multiline
                  rows={4}
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Send Message
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 4 }}>
                {/* <img
                  src="https://mejona.in/assets/img/about/about-2.png"
                  alt="Contact Us"
                  style={{ maxHeight: '50%', borderRadius: '8px' }}
                /> */}
              </Box>

              <Typography variant="h6" gutterBottom>
                Contact Information
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Phone color="primary" sx={{ mr: 2 }} />
                <Typography>+1 (123) 456-7890</Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Email color="primary" sx={{ mr: 2 }} />
                <Typography>contact@example.com</Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <LocationOn color="primary" sx={{ mr: 2 }} />
                <Typography>123 Main St, City, Country</Typography>
              </Box>

              <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                Follow Us
              </Typography>

              <Box>
                <IconButton color="primary" aria-label="Facebook">
                  <Facebook />
                </IconButton>
                <IconButton color="primary" aria-label="Twitter">
                  <Twitter />
                </IconButton>
                <IconButton color="primary" aria-label="Instagram">
                  <Instagram />
                </IconButton>
                <IconButton color="primary" aria-label="LinkedIn">
                  <LinkedIn />
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4, height: '300px', width: '100%' }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12345.67890!2d-73.123456!3d40.123456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zM40KDEyJzM0LjUiTiA3M8KwMDcnMzQuNSJX!5e0!3m2!1sen!2sus!4v1234567890123"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default ContactPage;