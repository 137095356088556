import React from 'react';
import { Box, Typography, Container, Grid, Paper, Button } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const Mission = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              borderBottom: '2px solid #000',
              pb: 1,
              display: 'inline-block',
              mb: 4,
            }}
          >
            Our Mission
          </Typography>
          <Typography variant="body1" paragraph>
            At NaturePlus, we're committed to enhancing your well-being through nature's finest ingredients. Our mission is to provide high-quality, scientifically-backed supplements that support your health journey.
          </Typography>
          <Typography variant="body1" paragraph>
            We believe in:
          </Typography>
          <ul>
            <li>Sourcing the purest, most potent natural ingredients</li>
            <li>Rigorous testing for safety and efficacy</li>
            <li>Sustainable and ethical practices</li>
            <li>Empowering you with knowledge about nutrition and wellness</li>
          </ul>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              bgcolor: '#f5f5f5',
              height: '100%',
              minHeight: 300,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              component="img"
              src="https://mejona.in/assets/img/service/transparency.jpg"
              alt="Natural supplements"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
            <Button
              variant="contained"
              startIcon={<PlayCircleOutlineIcon />}
              sx={{
                position: 'absolute',
                bottom: 16,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              Watch Our Story
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Mission;