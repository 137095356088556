import React from 'react';
import {
  Typography,
  Box,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import Footer from '../Components/Footer';
import Header from '../Components/Header';

const FullWidthPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: 0,
  borderRadius: 0,
  backgroundColor: '#f9f9f9',
  minHeight: '100vh',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '1200px',
  margin: '0 auto',
  padding: theme.spacing(0, 2),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
}));

const SubSectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  fontWeight: 'bold',
}));

const TermsAndConditions = () => {
  return (
    <>
    <Header/>
    <FullWidthPaper elevation={0}>
      <ContentWrapper>
        <Typography variant="h3" gutterBottom align="center" sx={{ fontWeight: 'bold', my: 4 }}>
          Terms and Conditions
        </Typography>
        <Typography variant="subtitle1" paragraph align="center">
          Last updated: July 4, 2024
        </Typography>
        <Divider sx={{ mb: 4 }} />
        
        <Typography paragraph>
          Welcome to NutriLife Supplements. These Terms and Conditions govern your use of our website (www.nutrilifesupplements.com) and the purchase of our products. By accessing our website or purchasing our products, you agree to be bound by these Terms and Conditions. Please read them carefully before using our services.
        </Typography>
        
        <SectionTitle variant="h5">1. Definitions</SectionTitle>
        <Typography paragraph>
          "Company," "we," "us," or "our" refers to NutriLife Supplements, Inc.<br />
          "Website" refers to www.nutrilifesupplements.com<br />
          "Products" refers to the dietary supplements and related goods offered for sale on our Website.<br />
          "User," "you," or "your" refers to the person accessing our Website or purchasing our Products.
        </Typography>
        
        <SectionTitle variant="h5">2. Use of the Website</SectionTitle>
        <Typography paragraph>
          2.1. Eligibility: You must be at least 18 years old to use this Website and purchase our Products. By using the Website, you represent and warrant that you are at least 18 years of age.
        </Typography>
        <Typography paragraph>
          2.2. Account Registration: Some features of the Website may require you to register an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
        </Typography>
        <Typography paragraph>
          2.3. Prohibited Activities: You agree not to engage in any of the following activities:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="- Using the Website for any unlawful purpose or in violation of these Terms and Conditions" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Attempting to gain unauthorized access to any portion of the Website" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Interfering with the proper working of the Website" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Engaging in any conduct that restricts or inhibits any other user from using or enjoying the Website" />
          </ListItem>
        </List>
        
        <SectionTitle variant="h5">3. Product Information</SectionTitle>
        <Typography paragraph>
          3.1. Product Descriptions: We strive to provide accurate product descriptions, including ingredients, suggested use, and potential benefits. However, we do not warrant that product descriptions or other content on the Website are accurate, complete, reliable, current, or error-free.
        </Typography>
        <Typography paragraph>
          3.2. Product Images: The images of the Products on our Website are for illustrative purposes only. The actual Product may vary slightly from these images due to packaging updates or manufacturing variations.
        </Typography>
        <Typography paragraph>
          3.3. Product Availability: All Products are subject to availability. We reserve the right to discontinue any Product at any time.
        </Typography>
        
        <SectionTitle variant="h5">4. Pricing and Payment</SectionTitle>
        <Typography paragraph>
          4.1. Pricing: All prices are listed in US Dollars and are subject to change without notice. We reserve the right to modify prices for Products displayed on the Website at any time without prior notice.
        </Typography>
        <Typography paragraph>
          4.2. Price Discrepancies: In the event a Product is listed at an incorrect price due to typographical error or error in pricing information received from our suppliers, we reserve the right to refuse or cancel any orders placed for the Product listed at the incorrect price.
        </Typography>
        <Typography paragraph>
          4.3. Payment Methods: We accept [list payment methods, e.g., Visa, MasterCard, American Express, PayPal]. By providing a payment method, you represent and warrant that you are authorized to use the designated payment method.
        </Typography>
        <Typography paragraph>
          4.4. Secure Transactions: All payments are processed securely through [Payment Processor Name]. We do not store your full credit card details on our servers.
        </Typography>
        
        <SectionTitle variant="h5">5. Shipping and Delivery</SectionTitle>
        <Typography paragraph>
          5.1. Shipping Address: We ship to the address provided by you during the checkout process. It is your responsibility to ensure the accuracy of the shipping address.
        </Typography>
        <Typography paragraph>
          5.2. Shipping Methods: We offer various shipping methods, including [list shipping methods, e.g., Standard, Express, Overnight]. Delivery times may vary depending on your location and chosen shipping method.
        </Typography>
        <Typography paragraph>
          5.3. International Shipping: For international orders, the recipient is responsible for all customs duties, taxes, and import fees. We are not responsible for any delays due to customs processing.
        </Typography>
        <Typography paragraph>
          5.4. Risk of Loss: The risk of loss and title for Products purchased from our Website pass to you upon our delivery to the carrier.
        </Typography>
        
        <SectionTitle variant="h5">6. Returns and Refunds</SectionTitle>
        <Typography paragraph>
          6.1. Return Policy: We offer a 30-day money-back guarantee on unopened Products. To be eligible for a return, your item must be unused and in the same condition that you received it.
        </Typography>
        <Typography paragraph>
          6.2. Return Process: To initiate a return, please contact our customer service team at returns@nutrilifesupplements.com. You will be provided with a return shipping address and instructions.
        </Typography>
        <Typography paragraph>
          6.3. Refunds: Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund. If approved, your refund will be processed, and a credit will automatically be applied to your original method of payment within [X] business days.
        </Typography>
        <Typography paragraph>
          6.4. Return Shipping Costs: Shipping costs for returns are the responsibility of the customer unless the Product is defective or we made an error in fulfilling your order.
        </Typography>
        
        <SectionTitle variant="h5">7. Health Disclaimer</SectionTitle>
        <Typography paragraph>
          7.1. Not Medical Advice: The information provided on our Website and Product labels is for informational purposes only and is not intended as a substitute for advice from your physician or other healthcare professional.
        </Typography>
        <Typography paragraph>
          7.2. No Medical Claims: Our Products are not intended to diagnose, treat, cure, or prevent any disease. We do not make any medical claims regarding our Products.
        </Typography>
        <Typography paragraph>
          7.3. Consult a Healthcare Professional: Always consult with a qualified healthcare professional before starting any supplement regimen, especially if you are pregnant, nursing, have a medical condition, or are taking other medications.
        </Typography>
        
        <SectionTitle variant="h5">8. Intellectual Property</SectionTitle>
        <Typography paragraph>
          8.1. Ownership: All content on this Website, including but not limited to text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of NutriLife Supplements or its content suppliers and protected by United States and international copyright laws.
        </Typography>
        <Typography paragraph>
          8.2. Limited License: We grant you a limited, revocable, and non-exclusive license to access and make personal use of the Website. This license does not include any resale or commercial use of the Website or its contents.
        </Typography>
        <Typography paragraph>
          8.3. Restrictions: You may not reproduce, duplicate, copy, sell, resell, or otherwise exploit for any commercial purpose any portion of the Website, use of the Website, or access to the Website without our express written consent.
        </Typography>
        
        <SectionTitle variant="h5">9. Limitation of Liability</SectionTitle>
        <Typography paragraph>
          9.1. Disclaimer of Warranties: The Website and Products are provided on an "as is" and "as available" basis without any representations or warranties, express or implied.
        </Typography>
        <Typography paragraph>
          9.2. Limitation of Liability: To the fullest extent permitted by applicable law, NutriLife Supplements shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="- Your access to or use of or inability to access or use the Website or Products" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Any conduct or content of any third party on the Website" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Any content obtained from the Website" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Unauthorized access, use, or alteration of your transmissions or content" />
          </ListItem>
        </List>
        <Typography paragraph>
          9.3. Jurisdictional Limitations: Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations may not apply to you.
        </Typography>
        
        <SectionTitle variant="h5">10. Privacy Policy</SectionTitle>
        <Typography paragraph>
          10.1. Data Collection and Use: Our Privacy Policy, which sets out how we collect, use, and protect your personal data, is incorporated into these Terms and Conditions by reference.
        </Typography>
        <Typography paragraph>
          10.2. Consent: By using our Website, you consent to the collection, use, and sharing of your information as described in our Privacy Policy. If you do not agree with our policies and practices, your choice is not to use our Website.
        </Typography>
        
        <SectionTitle variant="h5">11. Governing Law and Jurisdiction</SectionTitle>
        <Typography paragraph>
          These Terms and Conditions are governed by and construed in accordance with the laws of the State of [Your State], United States, without regard to its conflict of law principles. You agree to submit to the personal and exclusive jurisdiction of the courts located within [Your County], [Your State] for the resolution of any disputes arising from these Terms and Conditions or your use of the Website.
        </Typography>
        
        <SectionTitle variant="h5">12. Changes to Terms and Conditions</SectionTitle>
        <Typography paragraph>
          We reserve the right to update or modify these Terms and Conditions at any time without prior notice. Changes will be effective immediately upon posting to the Website. Your continued use of the Website following the posting of revised Terms and Conditions means that you accept and agree to the changes.
        </Typography>
        
        <SectionTitle variant="h5">13. Severability</SectionTitle>
        <Typography paragraph>
          If any provision of these Terms and Conditions is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect.
        </Typography>
        
        <SectionTitle variant="h5">14. Contact Information</SectionTitle>
        <Typography paragraph>
          If you have any questions about these Terms and Conditions, please contact us at:
        </Typography>
        <Typography>
          NutriLife Supplements, Inc.<br />
          1234 Wellness Avenue<br />
          Health City, HC 12345<br />
          United States<br />
          Email: legal@nutrilifesupplements.com<br />
          Phone: (555) 123-4567
        </Typography>
      </ContentWrapper>
      
    </FullWidthPaper>
    <Footer/>
    </>
  );
};

export default TermsAndConditions;