import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';

const TeamMember = ({ color }) => (
  <Paper
    elevation={3}
    sx={{
      width: 150,
      height: 150,
      backgroundColor: color,
      borderRadius: '16px',
    }}
  />
);

const TeamSection = () => {
  return (
    <Box sx={{ textAlign: 'center', p: 4 }}>
      <Typography variant="h4" component="h2" sx={{fontWeight: '900'}} gutterBottom>
        A TEAM OF EXPERTS
      </Typography>
      
      <Box sx={{ my: 4, fontWeight: '400', fontSize:'1.5rem' }}>
        <Typography variant="body1" sx={{ mb: 2, fontStyle: 'italic' }}>
          Short training programs but intensive bulk feedback! aniTe-peik anATe of each! Greatly enhance your jobbing brand 6 is
        </Typography>
        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
          and centrally, capitalize the internet auction, that chat platform in outreach to brands is a decade of two. Nitty details.
        </Typography>
      </Box>

      <Typography variant="h5" component="h3" color="var(--Final-red, #F00)" sx={{fontWeight: '700'}} gutterBottom>
        PEOPLE FIRST
      </Typography>

      <Grid container justifyContent="center" spacing={4}>
        <Grid item>
          <TeamMember color="#FFD1DC" />
        </Grid>
        <Grid item>
          <TeamMember color="#FFEFD5" />
        </Grid>
        <Grid item>
          <TeamMember color="#E6E6FA" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeamSection;