import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  InputBase,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Container,
  Avatar,
  ListItemIcon,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/History";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Cart from "./Cart";
import { useCart } from "../CartContext";
import Login from "./LoginSignup/Login";
import axios from "axios";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: "var(--main-yello, #FFD300)",
  boxShadow: "none",
  borderBottom: "1px solid #e0e0e0",
  position: "sticky",
  top: 0,
  zIndex: 999,
}));

const LogoImg = styled("img")({
  height: "40px",
  width: "auto",
  cursor: "pointer",
});

const NavButton = styled(Button)(({ theme }) => ({
  color: "#000",
  fontWeight: 900,
  padding: theme.spacing(1.5, 2),
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
}));

const PromoBar = styled("div")(({ theme }) => ({
  backgroundColor: "var(--Final-red, #F00)",
  padding: theme.spacing(1),
  textAlign: "center",
  color: "#fff",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Header = () => {
  const [cartOpen, setCartOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { cartItems } = useCart();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleCartOpen = () => setCartOpen(true);
  const handleCartClose = () => setCartOpen(false);
  const handleLoginOpen = () => setLoginOpen(true);
  const handleLoginClose = () => setLoginOpen(false);
  const handleMobileMenuToggle = () => setMobileMenuOpen(!mobileMenuOpen);

  const handleUserMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
    handleUserMenuClose();
    window.location.reload();
  };

  const handleOrderHistory = () => {
    navigate("/user-profile/order-history");
    handleUserMenuClose();
  };

  const handlMyAccountLink = () => {
    navigate("/user-profile");
    handleUserMenuClose();
  };
  // const handleWishlist = () => {
  //   navigate("/user-profile/wishlist");
  //   handleUserMenuClose();
  // };

  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const navItems = [
    { label: "Home", href: "/" },
    { label: "Shop", href: "/shop" },
    { label: "About Us", href: "/about" },
    {
      label: "Our Founder",
      href: location.pathname === "/" ? "#founder" : "/founder",
    },
    { label: "Athletes", href: "/athletes" },
    { label: "Our Team", href: "/team" },
    { label: "Contact", href: "/contact" },
  ];

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`/api/search?query=${searchQuery}`);
      setSearchResults(response.data);
      navigate("/search-results", { state: { results: response.data } });
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  useEffect(() => {
    if (searchQuery.length > 2) {
      const delayDebounce = setTimeout(() => {
        handleSearch({ preventDefault: () => {} });
      }, 300);

      return () => clearTimeout(delayDebounce);
    }
  }, [searchQuery]);

  return (
    <>
      <PromoBar>
        <Typography variant="body2" fontWeight={900}>
          Sign up and get 20% off on your first order.
          <Button
            onClick={() => {
              setIsSignup(true);
              handleLoginOpen();
            }}
            sx={{ fontWeight: 900, color: "var(--white, #FFE501)", ml: 2 }}
          >
            Sign Up
          </Button>
        </Typography>
      </PromoBar>

      <StyledAppBar position="static">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <a href="/" style={{ display: "flex" }}>
                <LogoImg
                  className="hmza-logo"
                  src="/img/hmza-logo-1.png"
                  alt="HMZA Logo"
                />
              </a>
            </Box>

            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <form onSubmit={handleSearch}>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </form>
            </Search>
            <IconButton color="inherit" onClick={handleCartOpen}>
              <Badge badgeContent={totalQuantity} color="primary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            {user ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={handleUserMenuOpen} color="inherit">
                  <Avatar sx={{ width: 32, height: 32, bgcolor: 'secondary.main' }}>
                    {user.name.charAt(0).toUpperCase()}
                  </Avatar>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleUserMenuClose}
                >
                  <MenuItem disabled>
                    <Typography variant="subtitle1">{user.name}</Typography>
                  </MenuItem>
                  <MenuItem onClick={handlMyAccountLink}>
                    <ListItemIcon>
                      <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    My Account
                  </MenuItem>
                  <MenuItem onClick={handleOrderHistory}>
                    <ListItemIcon>
                      <HistoryIcon fontSize="small" />
                    </ListItemIcon>
                    Order History
                  </MenuItem>
                  {/* <MenuItem onClick={handleWishlist}>
                    <ListItemIcon>
                      <FavoriteIcon fontSize="small" />
                    </ListItemIcon>
                    Wishlist
                  </MenuItem> */}
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <IconButton color="inherit" onClick={handleLoginOpen}>
                <PersonIcon />
              </IconButton>
            )}

          

            {isMobile && (
              <IconButton color="inherit" onClick={handleMobileMenuToggle}>
                {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            )}
          </Toolbar>
        </Container>

        {!isMobile && (
          <Box sx={{ bgcolor: "white" }}>
            <Container maxWidth="lg">
              <Toolbar disableGutters>
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  {navItems.map((item) => (
                    <NavButton key={item.label} href={item.href}>
                      {item.label}
                    </NavButton>
                  ))}
                </Box>
              </Toolbar>
            </Container>
          </Box>
        )}
      </StyledAppBar>

      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={handleMobileMenuToggle}
      >
        <List>
          {navItems.map((item) => (
            <ListItem
              button
              key={item.label}
              component="a"
              href={item.href}
              onClick={handleMobileMenuToggle}
            >
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Cart open={cartOpen} onClose={handleCartClose} />

      <Dialog
        open={loginOpen}
        onClose={handleLoginClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{isSignup ? 'Sign Up' : 'Login'}</DialogTitle>
        <DialogContent>
          <Login
            isSignup={isSignup}
            setIsSignup={setIsSignup}
            handleClose={() => {
              handleLoginClose();
              window.location.reload(); // Reload the page after successful login/signup
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Header;