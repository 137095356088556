import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Typography, 
  Box, 
  Tabs, 
  Tab,
  CircularProgress
} from '@mui/material';

const Login = ({ handleClose }) => {
  const [isSignup, setIsSignup] = useState(false);
  const [loginMethod, setLoginMethod] = useState('email');
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const [otp, setOtp] = useState('');

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleLoginMethodChange = (event, newValue) => {
    setLoginMethod(newValue);
  };

  const handleSendOtp = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://hmzafuel.com/APIs/auth.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'send_otp',
          email: formData.email,
          phone: formData.phone
        }),
      });
      const data = await response.json();
      if (data.success) {
        setOtpSent(true);
        alert('OTP sent successfully. Please check your email or phone.');
      } else {
        const errorMessage = data.message || 'Failed to send OTP';
        console.error('Error:', errorMessage);
        alert(errorMessage);
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`An error occurred while sending OTP: ${error.message}`);
    }
    setLoading(false);
  };
  
  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('https://hmzafuel.com/APIs/auth.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'verify_otp',
          email: formData.email,
          phone: formData.phone,
          name: formData.name,
          otp: otp
        }),
      });
      const data = await response.json();
      if (data.success) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        alert(isSignup ? 'Signup successful!' : 'Login successful!');
        handleClose();
        window.location.reload(); // Reload the page to update the header
      } else {
        const errorMessage = data.message || 'Failed to verify OTP';
        console.error('Error:', errorMessage);
        alert(errorMessage);
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`An error occurred while verifying OTP: ${error.message}`);
    }
    setLoading(false);
  };
  
  return (
    <Box component="form" onSubmit={handleVerifyOtp} sx={{ mt: 1 }}>
      <Tabs value={loginMethod} onChange={handleLoginMethodChange} centered sx={{ mb: 2 }}>
        <Tab value="email" label="Email" />
        <Tab value="phone" label="Phone" />
      </Tabs>

      {isSignup && (
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Full Name"
          name="name"
          autoComplete="name"
          autoFocus
          value={formData.name}
          onChange={handleChange}
        />
      )}

      {loginMethod === 'email' && (
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus={!isSignup}
          value={formData.email}
          onChange={handleChange}
        />
      )}

      {loginMethod === 'phone' && (
        <TextField
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Phone Number"
          name="phone"
          autoComplete="tel"
          autoFocus={loginMethod === 'phone'}
          value={formData.phone}
          onChange={handleChange}
        />
      )}

      {!otpSent ? (
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSendOtp}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Send OTP'}
        </Button>
      ) : (
        <>
          <TextField
            margin="normal"
            required
            fullWidth
            name="otp"
            label="OTP"
            type="text"
            id="otp"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : (isSignup ? 'Sign Up' : 'Sign In')}
          </Button>
        </>
      )}

      <Typography align="center">
        {isSignup ? 'Already have an account? ' : "Don't have an account? "}
        <Button onClick={() => setIsSignup(!isSignup)}>
          {isSignup ? 'Sign In' : 'Sign Up'}
        </Button>
      </Typography>
    </Box>
  );
};

export default Login;