import React, { useState } from 'react';
import { Box, IconButton, Container } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

const Hero = () => {
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Box 
        className='hero' 
        position="relative" 
        sx={{
          width: '100%',
          paddingTop: '56.25%', // 16:9 Aspect Ratio (9 / 16 = 0.5625)
          overflow: 'hidden',
        }}
      >
        <Box
          component="video"
          autoPlay
          loop
          muted={isMuted}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        >
          <source src="videos/hmza2video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </Box>
        <IconButton
          onClick={toggleMute}
          sx={{
            position: 'absolute',
            bottom: { xs: 8, sm: 16 },
            right: { xs: 8, sm: 16 },
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
            fontSize: { xs: '1rem', sm: '1.25rem' },
          }}
        >
          {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </Box>
    </Container>
  );
};

export default Hero;