import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Drawer,
  IconButton,
  AppBar,
  Toolbar,
  Container,
  Backdrop,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import ProductCard from "../ProductCard"; // Make sure this path is correct

const ProductList = ({ category }) => {
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState("null");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtering, setFiltering] = useState(false);
  const [error, setError] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const productsRef = useRef(null);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [productsResponse, categoriesResponse] = await Promise.all([
        axios.get('https://hmzafuel.com/APIs/product-details.php'),
        axios.get('https://hmzafuel.com/APIs/categories.php')
      ]);
      setProducts(productsResponse.data);
      setCategories(categoriesResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCategoryClick = (id) => {
    setFiltering(true);
    navigate(`/shop/${id}`);
    if (isSmallScreen) {
      setDrawerOpen(false);
    }
    if (productsRef.current) {
      productsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    setTimeout(() => setFiltering(false), 500);
  };

  const handleProductClick = (id, name) => {
    navigate(`/product/${id}/${encodeURIComponent(name)}`);
  };

  const handleSortChange = (event) => {
    setFiltering(true);
    setSortBy(event.target.value);
    setTimeout(() => setFiltering(false), 500);
  };

  const sortedAndFilteredProducts = useMemo(() => {
    let filteredProducts = category
      ? products.filter(product => product.category === category)
      : products;

    switch (sortBy) {
      case "price-asc":
        return filteredProducts.sort((a, b) => parseFloat(a.sale_price || a.price) - parseFloat(b.sale_price || b.price));
      case "price-desc":
        return filteredProducts.sort((a, b) => parseFloat(b.sale_price || b.price) - parseFloat(a.sale_price || a.price));
      case "name":
        return filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
      default:
        return filteredProducts;
    }
  }, [category, sortBy, products]);

  const selectedCategory = categories.find(cat => cat.id === category);

  const categoriesList = (
    <List>
      <ListItem
        button
        onClick={() => handleCategoryClick('')}
        selected={!category}
      >
        <ListItemText primary="All Products" />
      </ListItem>
      {categories.map((cat) => (
        <ListItem
          button
          key={cat.id}
          onClick={() => handleCategoryClick(cat.id)}
          selected={category === cat.id}
        >
          <ListItemText primary={cat.name} />
        </ListItem>
      ))}
    </List>
  );

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          {isSmallScreen && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {selectedCategory ? selectedCategory.name : "All Products"}
          </Typography>
          <Select
            value={sortBy}
            onChange={handleSortChange}
            sx={{ minWidth: 120 }}
          >
            <MenuItem value="null">SORT BY</MenuItem>
            <MenuItem value="price-asc">Price: Low to High</MenuItem>
            <MenuItem value="price-desc">Price: High to Low</MenuItem>
            <MenuItem value="name">Name</MenuItem>
          </Select>
        </Toolbar>
      </AppBar>

      <Container maxWidth="xl" sx={{ my: 3 }}>
        <Box sx={{ display: 'flex' }}>
          {!isSmallScreen && (
            <Box sx={{ width: 240, flexShrink: 0, mr: 3 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>Categories</Typography>
              {categoriesList}
            </Box>
          )}
          <Box ref={productsRef} sx={{ flexGrow: 1 }}>
            {sortedAndFilteredProducts.length === 0 ? (
              <Typography variant="h6" align="center">No products found in this category.</Typography>
            ) : (
              <Grid container spacing={3}>
                {sortedAndFilteredProducts.map((product) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                    <ProductCard
                      id={product.id}
                      name={product.name}
                      image={product.images[0] || "/img/image-38-5.png"}
                      price={parseFloat(product.price)}
                      salePrice={product.sale_price ? parseFloat(product.sale_price) : null}
                      rating={0}
                      description={product.description}
                      onProductClick={handleProductClick}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Box>
      </Container>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box sx={{ width: 240 }} role="presentation">
          <Typography variant="h6" sx={{ p: 2 }}>Categories</Typography>
          {categoriesList}
        </Box>
      </Drawer>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || filtering}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default ProductList;