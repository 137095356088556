import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  Grid,
  Card,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Backdrop,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  styled,
  Link
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(0.75),
  fontWeight: 'bold',
  textTransform: 'uppercase',
}));

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchOrderHistory = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://hmzafuel.com/APIs/order-history.php');
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching order history:", error);
      setError("Failed to fetch order history. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchOrderHistory();
  }, [fetchOrderHistory]);

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const getStatusColor = (status) => {
    switch(status.toLowerCase()) {
      case 'completed': return 'success';
      case 'processing': return 'info';
      case 'pending': return 'warning';
      default: return 'default';
    }
  };

  const getProductUrl = (id, name) => {
    return `/product/${id}/${encodeURIComponent(name)}`;
  };

  const OrderCard = ({ order }) => {
    const firstItem = order.items[0];
    return (
      <StyledCard>
        <Link component={RouterLink} to={getProductUrl(firstItem.id, firstItem.name)}>
          <CardMedia
            component="img"
            sx={{ width: 151 }}
            image={firstItem.image_path}
            alt={firstItem.name}
          />
        </Link>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography variant="h6" component="div">
              Order #{order.id}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {new Date(order.date).toLocaleDateString()}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {order.items.length} item(s)
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <Typography variant="h6" component="div">
                Rs.{order.total}
              </Typography>
              <StyledChip label={order.status} color={getStatusColor(order.status)} size="small" />
            </Box>
          </CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
            <Button size="small" onClick={() => handleViewOrder(order)}>View Details</Button>
          </Box>
        </Box>
      </StyledCard>
    );
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3, bgcolor: '#f5f5f5', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 4 }}>Order History</Typography>
      {orders.length === 0 && !loading ? (
        <Typography variant="h6" align="center">You haven't placed any orders yet.</Typography>
      ) : (
        <Grid container spacing={3}>
          {orders.map((order) => (
            <Grid item xs={12} sm={6} md={4} key={order.id}>
              <OrderCard order={order} />
            </Grid>
          ))}
        </Grid>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="order-details-dialog"
        fullScreen={isMobile}
      >
        <DialogTitle id="order-details-dialog">Order Details</DialogTitle>
        <DialogContent dividers>
          {selectedOrder && (
            <Box>
              <Typography variant="h6">Order #{selectedOrder.id}</Typography>
              <Typography variant="subtitle1">{new Date(selectedOrder.date).toLocaleString()}</Typography>
              <StyledChip 
                label={selectedOrder.status} 
                color={getStatusColor(selectedOrder.status)} 
                sx={{ my: 1 }} 
              />
              <Typography variant="h6" sx={{ mt: 2 }}>Items:</Typography>
              <Grid container spacing={2}>
                {selectedOrder.items.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <Link component={RouterLink} to={getProductUrl(item.id, item.name)}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={item.image_path}
                          alt={item.name}
                        />
                      </Link>
                      <CardContent>
                        <Link 
                          component={RouterLink} 
                          to={getProductUrl(item.id, item.name)}
                          color="inherit"
                          underline="hover"
                        >
                          <Typography variant="subtitle1">{item.name}</Typography>
                        </Link>
                        <Typography variant="body2">Quantity: {item.quantity}</Typography>
                        <Typography variant="body2">Price: Rs.{item.price}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Typography variant="h6" sx={{ mt: 2 }}>Total: Rs.{selectedOrder.total}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OrderHistory;