import React from 'react'
import Header from '../Components/Header'
import TeamSection from '../Components/Team/TeamSection'
import Footer from '../Components/Footer'

const Team = () => {
  return (
    <div>
        <Header/>
        <TeamSection/>
        <Footer/>
    </div>
  )
}

export default Team