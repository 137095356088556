import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Card, CardMedia, IconButton, Container } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const VideoCard = ({ videoSrc, poster, link, isPlaying, onPlay, onPause }) => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    videoRef.current.muted = isMuted;
  }, [isMuted]);

  const togglePlay = () => {
    if (videoRef.current.paused) {
      onPlay();
    } else {
      onPause();
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <Card sx={{ width: '100%', height: { xs: '80vh', sm: '60vh', md: 442 }, position: 'relative' }}>
      <CardMedia
        component="video"
        ref={videoRef}
        src={videoSrc}
        poster={poster}
        sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
        muted
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 16,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: 2,
        }}
      >
        <IconButton
          onClick={togglePlay}
          sx={{ bgcolor: 'rgba(255, 255, 255, 0.7)' }}
        >
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <IconButton
          onClick={toggleMute}
          sx={{ bgcolor: 'rgba(255, 255, 255, 0.7)' }}
        >
          {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </Box>
    </Card>
  );
};

const ScrollableVideoCards = () => {
  const [playingIndex, setPlayingIndex] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const videos = [
    { src: 'videos/hmzavideo.mp4', poster: 'img/image-42.png', link: 'https://example.com/1' },
    { src: 'videos/hmzavideo.mp4', poster: 'img/image-41.png', link: 'https://example.com/2' },
    { src: 'videos/hmza2video.mp4', poster: 'img/image-43.png', link: 'https://example.com/3' },
    { src: 'videos/hmzavideo.mp4', poster: 'img/image-41.png', link: 'https://example.com/4' },
  ];

  const handlePlay = (index) => {
    setPlayingIndex(index);
  };

  const handlePause = () => {
    setPlayingIndex(null);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 1 : isTablet ? 2 : 3,
    slidesToScroll: 1,
    arrows: !isMobile,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Typography
        variant={isMobile ? "h5" : "h4"}
        component="h2"
        align="center"
        sx={{ mb: { xs: 3, md: 4 }, fontWeight: "bold" }}
      >
        NEW LAUNCH
      </Typography>
      <Slider {...settings}>
        {videos.map((video, index) => (
          <Box key={index} sx={{ p: 1 }}>
            <VideoCard 
              videoSrc={video.src} 
              poster={video.poster} 
              link={video.link}
              isPlaying={playingIndex === index}
              onPlay={() => handlePlay(index)}
              onPause={handlePause}
            />
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default ScrollableVideoCards;