import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Divider,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton"; // Import IconButton from Material-UI
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon from Material-UI Icons

import { styled } from "@mui/system";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useCart } from "../CartContext";
import PaymentMethodStep from "./PaymentMethodStep";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: "#00B0FF",
  color: "white",
  "&:hover": {
    backgroundColor: "var(--main-yello, #FFD300)",
  },
}));

const OtpInput = styled(TextField)(({ theme }) => ({
  width: "3rem",
  marginRight: theme.spacing(1),
  border: "1px solid var(--final-grey, #787485)",
  borderRadius: "8px",
  "& .MuiInputBase-input": {
    textAlign: "center",
  },
}));

const CheckoutComponent = ({ onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Mobile", "Address", "Pay"];
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isOtpComplete, setIsOtpComplete] = useState(false);
  const { cartItems } = useCart();
  const otpRefs = [useRef(), useRef(), useRef(), useRef()];
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [addressErrors, setAddressErrors] = useState({});
  const [otpVerificationStatus, setOtpVerificationStatus] = useState(null);
  const [otpVerificationMessage, setOtpVerificationMessage] = useState("");

  useEffect(() => {
    let interval;
    if (otpSent && resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [otpSent, resendTimer]);

  const startResendTimer = () => {
    setResendTimer(60); // 15 seconds timer
  };

  // Call startResendTimer() in sendOTP function after successful OTP send

  const [addressForm, setAddressForm] = useState({
    country: "",
    firstName: "",
    lastName: "",
    address: "",
    apartment: "",
    city: "",
    pinCode: "",
    email: "",
  });

  const validatePhone = () => {
    if (!phone || phone.length < 10) {
      setPhoneError("Please enter a valid phone number");
      return false;
    }
    setPhoneError("");
    return true;
  };

  const sendOTP = async () => {
    if (phone.length < 10) {
      setPhoneError("Please enter a valid phone number");
      return;
    }
    try {
      // Replace with your actual API call
      const response = await fetch("https://mejona.site/apitest/apitest.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phone }),
      });
      if (response.ok) {
        setOtpSent(true);
        setOtpSent(true);
        startResendTimer();
      } else {
        // Handle error
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const verifyOTP = async () => {
    try {
      // Replace with your actual API call
      const response = await fetch(
        "https://mejona.site/apitest/verify-otp.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ phone, otp: otp.join("") }),
        }
      );
      if (response.ok) {
        setOtpVerified(true);
        setOtpVerificationStatus("success");
        setOtpVerificationMessage("OTP verified successfully!");
        handleContinue();
      } else {
        setOtpVerificationStatus("error");
        setOtpVerificationMessage("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setOtpVerificationStatus("error");
      setOtpVerificationMessage("An error occurred. Please try again.");
    }
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 3) {
      otpRefs[index + 1].current.focus();
    }

    setIsOtpComplete(newOtp.every((digit) => digit !== ""));
  };

  // Call startResendTimer() in sendOTP function after successful OTP send

  const handleAddressChange = (e) => {
    setAddressForm({ ...addressForm, [e.target.name]: e.target.value });
  };

  const handleContinue = () => {
    if (activeStep === 0) {
      if (!otpVerified) {
        setPhoneError("Please verify your phone number");
        return;
      }
    } else if (activeStep === 1) {
      if (!validateAddressForm()) {
        return;
      }
    } else if (activeStep === 2) {
      if (!validatePayment()) {
        return;
      }
    }

    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      // Handle final submission
      handleFinalSubmit();
    }
  };

  const validateAddressForm = () => {
    const errors = {};
    if (!addressForm.country) errors.country = "Country is required";
    if (!addressForm.firstName) errors.firstName = "First name is required";
    if (!addressForm.lastName) errors.lastName = "Last name is required";
    if (!addressForm.address) errors.address = "Address is required";
    if (!addressForm.city) errors.city = "City is required";
    if (!addressForm.pinCode) errors.pinCode = "PIN code is required";
    if (!/^\d{6}$/.test(addressForm.pinCode))
      errors.pinCode = "Invalid PIN code";
    if (!addressForm.email) errors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(addressForm.email))
      errors.email = "Invalid email format";

    setAddressErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validatePayment = () => {
    // Implement payment validation logic
    return true; // Placeholder
  };

  const handleFinalSubmit = () => {
    // Handle the final submission of the order
    console.log("Order submitted");
    // You might want to call an API here to process the order
  };

  const total = cartItems.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mt: 3,
                mb: 3,
                textAlign: "center",
              }}
              gutterBottom
            >
              Verify Mobile Number
            </Typography>
            <PhoneInput
              country={"in"}
              value={phone}
              onChange={(value) => {
                setPhone(value);
                setPhoneError("");
              }}
              inputStyle={{
                paddingTop: "25px",
                paddingBottom: "25px",
                width: "100%",
                maxWidth: "350px",
                borderRadius: "8px",
                background: "var(--white, #FFF)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
              containerStyle={{
                width: "100%",
                maxWidth: "350px",
              }}
              buttonStyle={{
                background: "transparent",
                border: "none",
                borderRight: "1px solid #ccc",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
              }}
              isValid={(value, country) => {
                if (value.length < 10) {
                  setPhoneError("Please enter a valid phone number");
                  return false;
                }
                setPhoneError("");
                return true;
              }}
            />
            {phoneError && (
              <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                {phoneError}
              </Typography>
            )}
            {!otpSent && (
              <StyledButton
                onClick={sendOTP}
                disabled={!phone || !!phoneError}
                sx={{ mt: 2 }}
              >
                Send OTP
              </StyledButton>
            )}
            {otpSent && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "30px",
                    my: 3,
                    width: "100%",
                  }}
                >
                  {[0, 1, 2, 3].map((index) => (
                    <OtpInput
                      key={index}
                      value={otp[index]}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      inputProps={{ maxLength: 1 }}
                      inputRef={otpRefs[index]}
                    />
                  ))}
                </Box>
                <Box sx={{ textAlign: "center", width: "100%" }}>
                  <Typography variant="body2" color="primary">
                    {resendTimer > 0 ? (
                      <>
                        Resend OTP in{" "}
                        <span style={{ color: "var(--main-yello, #FFD300)" }}>
                          00:{resendTimer.toString().padStart(2, "0")}
                        </span>
                      </>
                    ) : (
                      <Button onClick={sendOTP}>Resend OTP</Button>
                    )}
                  </Typography>
                </Box>

                {isOtpComplete && (
                  <Box sx={{ maxWidth: "350px", mt: 2, width: "100%" }}>
                    <StyledButton fullWidth onClick={verifyOTP}>
                      Verify OTP
                    </StyledButton>
                  </Box>
                )}

                {otpVerificationStatus && (
                  <Box sx={{ mt: 2, textAlign: "center", width: "100%" }}>
                    <Typography
                      color={
                        otpVerificationStatus === "success"
                          ? "success.main"
                          : "error.main"
                      }
                      sx={{ fontWeight: "bold" }}
                    >
                      {otpVerificationMessage}
                    </Typography>
                  </Box>
                )}
              </>
            )}

            {otpVerified && (
              <Box sx={{ maxWidth: "350px", mt: 2, width: "100%" }}>
                <StyledButton fullWidth onClick={handleContinue}>
                  Continue
                </StyledButton>
              </Box>
            )}
          </Box>
        );
      case 1:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Address Details
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              name="country"
              label="Country/Region"
              value={addressForm.country}
              onChange={handleAddressChange}
              error={!!addressErrors.country}
              helperText={addressErrors.country}
              sx={{
                borderRadius: "8px",
                border: "0px solid #000",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="firstName"
                  label="First name"
                  value={addressForm.firstName}
                  onChange={handleAddressChange}
                  error={!!addressErrors.firstName}
                  helperText={addressErrors.firstName}
                  sx={{
                    borderRadius: "8px",
                    border: "0px solid #000",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="lastName"
                  label="Last name"
                  value={addressForm.lastName}
                  onChange={handleAddressChange}
                  error={!!addressErrors.lastName}
                  helperText={addressErrors.lastName}
                  sx={{
                    borderRadius: "8px",
                    border: "0px solid #000",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              fullWidth
              margin="normal"
              name="address"
              label="Address"
              value={addressForm.address}
              onChange={handleAddressChange}
              error={!!addressErrors.address}
              helperText={addressErrors.address}
              sx={{
                borderRadius: "8px",
                border: "0px solid #000",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              name="apartment"
              label="Apartment, suite, etc."
              value={addressForm.apartment}
              onChange={handleAddressChange}
              error={!!addressErrors.apartment}
              helperText={addressErrors.apartment}
              sx={{
                borderRadius: "8px",
                border: "0px solid #000",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="city"
                  label="City"
                  value={addressForm.city}
                  onChange={handleAddressChange}
                  error={!!addressErrors.city}
                  helperText={addressErrors.city}
                  sx={{
                    borderRadius: "8px",
                    border: "0px solid #000",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="pinCode"
                  label="PIN code"
                  value={addressForm.pinCode}
                  onChange={handleAddressChange}
                  error={!!addressErrors.pinCode}
                  helperText={addressErrors.pinCode}
                  sx={{
                    borderRadius: "8px",
                    border: "0px solid #000",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              fullWidth
              margin="normal"
              name="email"
              label="Email address"
              type="email"
              value={addressForm.email}
              onChange={handleAddressChange}
              error={!!addressErrors.email}
              helperText={addressErrors.email}
              sx={{
                borderRadius: "8px",
                border: "0px solid #000",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Save information for next time"
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Email me with news and offers"
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button onClick={handleBack}>Back</Button>
              <StyledButton onClick={handleContinue}>
                Continue to Payment
              </StyledButton>
            </Box>
          </>
        );
      case 2:
        return <PaymentMethodStep total={total} />;
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  return (
    <Box sx={{ margin: "auto", py: 3 }}>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <StyledPaper>{renderStepContent()}</StyledPaper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Order Summary
              </Typography>
              {cartItems.map((item) => (
    <Box
      key={item.id}
      sx={{ display: "flex", alignItems: "center", mb: 2 }}
    >
      <CardMedia
        component="img"
        sx={{ width: 50, height: 50, marginRight: 2 }}
        image={item.image}
        alt={item.name}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body1">{item.name}</Typography>
        <Typography variant="body2" color="text.secondary">
          Quantity: {item.quantity}
        </Typography>
      </Box>
      <Typography variant="body1">
        {new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(item.price * item.quantity)}
      </Typography>
    </Box>
  ))}
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography variant="body1">Subtotal</Typography>
                <Typography variant="body1">
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                  }).format(total)}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography variant="body1">Discount on MRP</Typography>
                <Typography variant="body1">-</Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography variant="body1">Shipping</Typography>
                <Typography variant="body1">-</Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
              >
                <Typography variant="h6">Total</Typography>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                  }).format(total)}
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <Select
            fullWidth
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <Typography color="text.secondary">Coupons</Typography>;
              }
              return selected;
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem disabled value="">
              <em>Coupons</em>
            </MenuItem>
            {/* Add coupon options here */}
          </Select>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckoutComponent;
