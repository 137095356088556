import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CartProvider } from "./CartContext";
import HomePage from "./Pages/HomePage";
import Shop from "./Pages/Shop";
import Product from "./Pages/Product";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import WhatsAppButton from "./Components/WhatsAppButton";
import About from "./Pages/About";
import Founder from "./Pages/Founder";
import Athletes from "./Pages/Athletes";
import Team from "./Pages/Team";
import Contact from "./Pages/Contact";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import UserProfile from "./Pages/UserProfile";

const App = () => {
  return (
    <CartProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/product" element={<Product />} />
            <Route path="/product/:id/:name" element={<Product />} />
            <Route path="/shop/:category?" element={<Shop />} />
            <Route path="/about" element={<About />} />
            <Route path="/founder" element={<Founder />} />
            <Route path="/Athletes" element={<Athletes />} />
            <Route path="/team" element={<Team />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/Terms" element={<TermsAndConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/user-profile/:section" element={<UserProfile />} />
          </Routes>
        </Router>
        <WhatsAppButton />
      </ThemeProvider>
    </CartProvider>
  );
};

export default App;
