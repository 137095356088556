import React from "react";
import { Typography, Button, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const OurFounder = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ width: '100%', margin: 0, padding: 0 }} id="founder">
      <Typography
        variant={isMobile ? "h5" : "h4"}
        component="h2"
        align="center"
        sx={{ mb: { xs: 3, md: 4 }, fontWeight: "bold" }}
      >
        OUR FOUNDER
      </Typography>
      <Box sx={{
        bgcolor: 'var(--main-yello, #ffd300)',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        pt: 2,
        width: '100%'
      }}>
        <Box sx={{
          width: { xs: '100%', sm: '35%' },
          mb: { xs: 3, sm: 0 }
        }}>
          <img 
            src="img/image-43.png" 
            alt="Founder"
            style={{
              width: '100%',
              height: isMobile ? '300px' : 'auto',
              objectFit: 'cover'
            }}
          />
        </Box>
        <Box sx={{
          width: { xs: '100%', sm: '65%' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          pl: { xs: 0, sm: 3 },
          pr: { xs: 0, sm: 3 }
        }}>
          <Box sx={{ width: '163px', height: '56px', mb: 2 }}>
            <img className="hmza-logo-2" src="img/hmza-logo-2.png" alt="HMZA Logo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          </Box>
          <Typography sx={{
            maxWidth: "629px",
            color: "#000",
            textAlign: "center",
            fontFamily: "Blender Pro",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "normal",
            mb: 2
          }}>
            HMZA is a brand founded by Hamza Khan, a three-time National Judo
            champion and accomplished combat sports athlete. With over a
            decade of experience in practicing Judo and various martial arts
            disciplines, Hamza has earned multiple medals in national
            championships.
          </Typography>
          <Button 
            variant="contained" 
            sx={{ 
              bgcolor: 'red',
              color: 'white',
              marginBottom:'10px',
              '&:hover': {
                bgcolor: 'darkred'
              }
            }}
          >
            Know More
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OurFounder;