import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import UpiIcon from '@mui/icons-material/AccountBalance';
import CardIcon from '@mui/icons-material/CreditCard';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BankIcon from '@mui/icons-material/AccountBalance';
import CashIcon from '@mui/icons-material/LocalAtm';

const PaymentMethodStep = ({ total }) => {
  const paymentMethods = [
    { id: 'upi', name: 'Pay via UPI', description: 'Use any registered UPI ID', icon: UpiIcon },
    { id: 'card', name: 'Debit/Credit Cards', description: 'Visa, Mastercard, RuPay & more', icon: CardIcon },
    { id: 'wallet', name: 'Wallets', description: 'Paypal, Airtel, PayZapp & more', icon: WalletIcon },
    { id: 'netbanking', name: 'Netbanking', description: 'Select from a list of banks', icon: BankIcon },
    { id: 'cod', name: 'Cash on delivery', description: 'Pay with cash', icon: CashIcon },
  ];

  // Format the total to Indian Rupees
  const formattedTotal = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(total);

  return (
    <Box sx={{ width: '100%', maxWidth: 600, mx: 'auto' }}>
      <Typography variant="h6" gutterBottom>
        Payment Method
      </Typography>
      <List>
        {paymentMethods.map((method) => (
          <Paper elevation={2} sx={{ mb: 2 }} key={method.id}>
            <ListItem button>
              <method.icon sx={{ mr: 2 }} />
              <ListItemText 
                primary={method.name} 
                secondary={method.description}
              />
              <ListItemSecondaryAction>
                <Typography variant="body1" sx={{ mr: 2 }}>
                  {formattedTotal}
                </Typography>
                <IconButton edge="end">
                  <ChevronRightIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Paper>
        ))}
      </List>
    </Box>
  );
};

export default PaymentMethodStep;