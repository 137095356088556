import React from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  IconButton,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";

const YellowBox = styled(Box)({
  background: "var(--main-yello, #FFD300)",
  padding: "20px",
  display: "flex",
  justifyContent: "center",
  gap: "50px",
  alignItems: "center",
});

const RedBox = styled(Box)({
  background: "var(--Final-red, #F00)",
  padding: "40px 20px",
  color: "#FFF",
});

const Logo = styled(Typography)({
  fontWeight: "bold",
  width: "132px",
  height: "46px",
});

const StyledTypography = styled(Typography)({
  color: "var(--white, #FFF)",
  fontFamily: " Blender Pro",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
});

const SocialIcon = styled(IconButton)({
  width: "66px",
  height: "66px",
  color: "#FFF",
  cursor: "pointer",
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  display: "block",
  marginBottom: "8px",
  color: "var(--white, #FFF)",
  fontFamily: " Blender Pro",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  "&:hover": {
    textDecoration: "underline",
  },
});

const Footer = () => {
  return (
    <Box>
      <YellowBox>
        <StyledTypography
          sx={{
            color: "#000",
            textAlign: "center",
            fontFamily: "Blender Pro",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            letterSpacing: "1.44px",
          }}
        >
          Subscribe to our NEWSLETTER
        </StyledTypography>
        <TextField
          variant="outlined"
          placeholder="Email address"
          sx={{
            backgroundColor: "#FFF",
            width: "300px",
            borderRadius: "8px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent",
              },
            },
          }}
        />
      </YellowBox>
      <RedBox sx={{ px: 5 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Logo>
              <img class="hmza-logo-3" src="/img/hmza-logo-2-1.png" />
            </Logo>
            <StyledTypography sx={{ mt: 2, mb: 2, fontSize: "16px" }}>
              WE ARE PROTEIN-LED FOOD COMPANY THAT PRIDES ITSELF IN MAKING
              PRODUCTS THAT'S GOOD FOR YOU. ALL WITHOUT ANY ARTIFICIAL COLOURS,
              CHEMICALS OR PRESERVATIVES
            </StyledTypography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <SocialIcon>
                <InstagramIcon />
              </SocialIcon>
              <SocialIcon>
                <LinkedInIcon />
              </SocialIcon>
              <SocialIcon>
                <FacebookIcon />
              </SocialIcon>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
  <StyledTypography variant="h6" gutterBottom>
    SHOP
  </StyledTypography>
  {[
    { name: "ALL PRODUCTS", href: "/shop" },
    { name: "NEW LAUNCHES & OFFERS", href: "/shop/new" },
    { name: "PROTEIN", href: "/shop/protein" },
    { name: "COOKIES & BARS", href: "/shop/cookies-bars" },
    { name: "ENERGY DRINKS", href: "/shop/energy-drinks" },
    { name: "SUPPLEMENTS", href: "/shop/supplements" },
  ].map((item) => (
    <StyledLink key={item.name} href={item.href}>
      {item.name}
    </StyledLink>
  ))}
</Grid>
          <Grid item xs={12} md={3}>
            <StyledTypography variant="h6" gutterBottom>
              QUICK LINKS
            </StyledTypography>
            {[
              { text: "SHIPPING & DELIVERY", href: "#" },
              { text: "TERMS AND CONDITIONS", href: "/terms" },
              { text: "PRIVACY POLICY", href: "privacypolicy" },
            ].map((item) => (
              <StyledLink key={item.text} href={item.href}>
                {item.text}
              </StyledLink>
            ))}
          </Grid>
          <Grid className="footerP" item xs={12} md={3}>
            <StyledTypography variant="h6" gutterBottom>
              CONTACT US
            </StyledTypography>
            <p>PH: 96847463383</p>
            <p>EMAIL: contact@hmzafuel.com</p>
            <p>WE TRY TO RESPOND WITH 24 HOURS</p>
            <p>WE ARE AVAILABLE MONDAY TO SATURDAY: 10AM-6:00 PM</p>
          </Grid>
        </Grid>
      </RedBox>
    </Box>
  );
};

export default Footer;
