import React from 'react'
import ContactForm from '../Components/Contact/ContactForm'
import ContactPage from '../Components/Contact/ContactForm'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

const Contact = () => {
  return (
    <div>
    <Header/>
        <ContactPage/>
        <Footer/>
    </div>
  )
}

export default Contact