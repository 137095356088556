import React from 'react';
import { Box, TextField, Grid, Avatar } from '@mui/material';

const UserInfo = () => {
  return (
    <Box>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} display="flex" justifyContent="center">
          <Avatar
            sx={{ width: 100, height: 100, bgcolor: 'yellow' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Name" variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Email" variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Phone Number" variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Date Of Birth" variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Gender" variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="City" variant="outlined" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserInfo;