import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Components/Header';
import Carousel from '../Components/Shop/Carousel';
import ProductList from '../Components/Shop/ProductList';
import Footer from '../Components/Footer';

const Shop = () => {
  const { category } = useParams();
  
  const carouselItems = [
    {
      image: '/img/HMZA_Banner/image.png',
      alt: 'Raw Chocolate Bars',
      link: '/products/chocolate'
    },
    {
      image: '/img/HMZA_Banner/image2.png',
      alt: 'Raw Protein Bars',
      link: '/products/protein-bars'
    },
    // Add more items as needed
  ];

  return (
    <div>
      <Header />
      <Carousel items={carouselItems} />
      <ProductList category={category} />
      <Footer/>
    </div>
  );
};

export default Shop;