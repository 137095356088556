import React from "react";
import { Modal, Box } from "@mui/material";
import CheckoutComponent from "./CheckoutComponent";

const CheckoutModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="checkout-modal"
      aria-describedby="checkout-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "90%",
          maxWidth: 900,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflow: "auto",
          borderRadius:'8px',
        }}
      >
        <CheckoutComponent onClose={onClose} />
      </Box>
    </Modal>
  );
};

export default CheckoutModal;
