import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HelpIcon from '@mui/icons-material/Help';
import UserInfo from '../Components/UserPage/UserInfo';
import Wishlist from '../Components/UserPage/Wishlist';
import OrderHistory from '../Components/UserPage/OrderHistory';
import HelpCenter from '../Components/UserPage/HelpCenter';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const drawerWidth = 240;

const UserProfile = () => {
  const { section } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const menuItems = [
    { text: 'User Info', icon: <PersonIcon />, component: <UserInfo />, path: 'user-info' },
    // { text: 'Wishlist', icon: <FavoriteIcon />, component: <Wishlist />, path: 'wishlist' },
    { text: 'My Order', icon: <LocalShippingIcon />, component: <OrderHistory />, path: 'order-history' },
    { text: 'Help Center', icon: <HelpIcon />, component: <HelpCenter />, path: 'help-center' },
  ];

  const [selectedOption, setSelectedOption] = useState(menuItems.find(item => item.path === section)?.text || 'User Info');

  useEffect(() => {
    const currentPath = section;
    const currentMenuItem = menuItems.find(item => item.path === currentPath);
    if (currentMenuItem) {
      setSelectedOption(currentMenuItem.text);
    }
  }, [section]);

  const handleMenuItemClick = (path, text) => {
    navigate(`/user-profile/${path}`);
    setSelectedOption(text);
  };

  const drawer = (
    <List>
      <ListItem>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>USER PROFILE</Typography>
      </ListItem>
      {menuItems.map((item) => (
        <ListItem 
          button 
          key={item.text} 
          onClick={() => handleMenuItemClick(item.path, item.text)}
          selected={selectedOption === item.text}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <div>
      <Header />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                position: 'relative',
                height: 'auto',
                zIndex: '500',
              },
            }}
            PaperProps={{ elevation: 0 }}
          >
            {drawer}
          </Drawer>
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, bgcolor: 'background.default' }}
          >
            {menuItems.find(item => item.text === selectedOption)?.component}
          </Box>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default UserProfile;
