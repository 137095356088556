// ProductList.js
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, Typography, Container, useMediaQuery, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ProductCard from "./ProductCard";
import axios from "axios";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ProductList = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [products, setProducts] = useState([]);
  const scrollRef = useRef(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://hmzafuel.com/APIs/product-details.php', {
          cancelToken: source.token
        });
        setProducts(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error("Error fetching products:", error);
        }
      }
    };

    fetchProducts();

    return () => {
      source.cancel('Component unmounted');
    };
  }, []);

  const handleProductClick = useCallback((productId, productName) => {
    navigate(`/product/${productId}/${encodeURIComponent(productName)}`);
  }, [navigate]);

  const productWidth = isMobile ? '80%' : isTablet ? '45%' : '30%';

  const scroll = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    }
  };

  return (
    <Container sx={{ py: { xs: 4, md: 6 }, position: 'relative',  maxWidth:'95%' }}>
      <Typography
        variant={isMobile ? "h5" : "h4"}
        component="h2"
        align="center"
        sx={{ mb: { xs: 3, md: 4 }, fontWeight: "bold" }}
      >
        TOP SELLING
      </Typography>
      <Box sx={{ position: 'relative' }}>
        <IconButton
          onClick={() => scroll(-300)}
          sx={{ position: 'absolute', left: -20, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <Box
          ref={scrollRef}
          sx={{
            display: 'flex',
            overflowX: 'scroll',
            scrollSnapType: 'x mandatory',
            gap: 2,
            py: 2,
          }}
        >
          {products.map((product) => (
            <Box
              key={product.id}
              sx={{
                flex: `0 0 ${productWidth}`,
                scrollSnapAlign: 'start',
                padding: 1,
                boxSizing: 'border-box',
                minWidth: isMobile ? '250px' : isTablet ? '300px' : '350px',
              }}
            >
              <ProductCard 
  id={product.id}
  name={product.name}
  image={product.images?.[0]?.image_path || '/img/image-38-5.png'}
  price={parseFloat(product.price)}
  salePrice={product.sale_price ? parseFloat(product.sale_price) : null}
  description={product.description}
  onProductClick={() => handleProductClick(product.id, product.name)}
/>
            </Box>
          ))}
        </Box>
        <IconButton
          onClick={() => scroll(300)}
          sx={{ position: 'absolute', right: -20, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Container>
  );
};

export default ProductList;