import React from 'react';
import { Box, Typography, Container, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

const HeroSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container 
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      sx={{ 
        width: '100%', // Full width
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        py: 4,
        bgcolor: '#F9F4ED;', // Off-white background
      }}
    >
      <Box 
        component={motion.div}
        initial={{ x: -50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        sx={{ 
          mb: isMobile ? 4 : 0
        }}
      >
        <Typography 
          variant="h4" 
          component="h1" 
          sx={{ 
            fontWeight: 700,
            mb: 2,
            letterSpacing: 1,
          }}
        >
          BRAND FOUNDER
        </Typography>
        <Typography 
          variant="h2" 
          component="h2" 
          sx={{ 
            color: 'red',
            fontWeight: 700,
            letterSpacing: 2,
          }}
        >
          HAMZA KHAN
        </Typography>
      </Box>
      
      <Box
        component={motion.div}
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
        sx={{
          position: 'relative',
          width: isMobile ? '100%' : '40%', // Full width on mobile, 40% otherwise
          paddingTop: isMobile ? '100%' : '40%', // Maintain aspect ratio
          maxWidth: 400,
          maxHeight: 400,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
            borderRadius: '50%',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          }}
        >
          <Box
            component="img"
            src="/img/image-43.png"
            alt="Hamza Khan"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          <Box
            component={motion.div}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            sx={{
              position: 'absolute',
              bottom: '10%',
              left: '50%',
              transform: 'translateX(-50%) rotate(-10deg)',
              bgcolor: 'rgba(255, 255, 0, 0.8)',
              px: 2,
              py: 1,
              borderRadius: 1,
            }}
          >
            <Typography variant="body2" sx={{ color: 'red', fontWeight: 700, textAlign: 'center' }}>
              THE FOUNDER
            </Typography>
            <Typography variant="body2" sx={{ color: 'red', fontWeight: 700, textAlign: 'center' }}>
              HAMZA KHAN
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default HeroSection;
