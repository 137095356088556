import React from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Container,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from "@mui/material/styles";

const Reviews = () => {
  const theme = useTheme();

  const reviews = [
    {
      title: "GREAT TASTE AND FLAVOUR",
      content:
        "This protein supplement has significantly enhanced my workouts and recovery, highly recommend it!",
      author: "Seff Thomas",
      image: "img/image-24.png",
    },
    {
      title: "FASTER DELIVERY",
      content:
        "This protein supplement has significantly enhanced my workouts and recovery, highly recommend it!",
      author: "Seff Thomas",
      image: "img/image-24.png",
    },
    // Add more reviews as needed
  ];

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <IconButton
        onClick={onClick}
        sx={{ 
          position: "absolute", 
          left: { xs: 10, sm: 30, md: 70 }, 
          bottom: 10, 
          zIndex: 1,
          transform: "scale(0.7)",
          "@media (min-width:600px)": {
            transform: "scale(1)",
          },
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
        >
          <path
            d="M47.5389 21.9625L47.7353 28.2094L13.3772 29.2894L13.5736 35.5363L3.90869 26.4605L12.9845 16.7956L13.1809 23.0425L47.5389 21.9625Z"
            fill="black"
          />
        </svg>
      </IconButton>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <IconButton
        onClick={onClick}
        sx={{ 
          position: "absolute", 
          right: { xs: 10, sm: 30, md: 'calc(40% + 70px)' }, 
          bottom: 10, 
          zIndex: 1,
          transform: "scale(0.7)",
          "@media (min-width:600px)": {
            transform: "scale(1)",
          },
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
        >
          <path
            d="M3.89844 29.1003V22.8503H38.2734V16.6003L47.6484 25.9753L38.2734 35.3503V29.1003H3.89844Z"
            fill="black"
          />
        </svg>
      </IconButton>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <Box sx={{ position: "relative", my: 3, overflow: "hidden" }}>
      <Box
        sx={{
          bgcolor: "#FFD300",
          height: { xs: "500px", sm: "400px" },
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />

      <Container maxWidth="lg">
        <Typography
          variant="h4"
          component="h2"
          align="center"
          sx={{ mb: { xs: 3, md: 4 }, color: "#F00", fontWeight: "bold" }}
        >
          CUSTOMER REVIEWS
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Card
            sx={{
              maxWidth: 1000,
              width: "100%",
              borderRadius: "30px",
              overflow: "hidden",
              boxShadow: 3,
            }}
          >
            <Slider {...settings}>
              {reviews.map((review, index) => (
                <div key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    <CardContent
                      className="reviewslider"
                      sx={{
                        width: { xs: "100%", md: "60%" },
                        bgcolor: "#FFF5E6",
                        p: { xs: 2, sm: 3, md: 4 },
                      }}
                    >
                      <Typography variant="h5" gutterBottom>
                        {review.title}
                      </Typography>
                      <Typography variant="body1" paragraph>
                        "{review.content}"
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {review.author}
                      </Typography>
                    </CardContent>
                    <CardMedia
                      component="img"
                      sx={{
                        width: { xs: "100%", md: "40%" },
                        height: { xs: 200, md: "auto" },
                        objectFit: "cover",
                      }}
                      image={review.image}
                      alt="Review"
                    />
                  </Box>
                </div>
              ))}
            </Slider>
          </Card>
        </Box>
      </Container>
    </Box>
  );
};

export default Reviews;