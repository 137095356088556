import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Container, Grid, Button, Card, CardMedia } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const GlimpseOfAchievements = () => {
  const [activeVideo, setActiveVideo] = useState(null);
  const videoRefs = useRef([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const achievements = [
    {
      video: "videos/hmzavideo.mp4",
      thumbnail: "img/image-73.png",
      title: "ATHLETE HMZA",
    },
    {
      video: "/videos/hmza2video.mp4",
      thumbnail: "img/image-74.png",
      title: "3X NATIONAL JUDO CHAMPION",
    },
    {
      video: "videos/hmzavideo.mp4",
      thumbnail: "img/image-74.png",
      title: "COMBAT SPORTS ATHLETE",
    }
  ];

  const handleVideoPlay = (index) => {
    if (activeVideo !== null && activeVideo !== index) {
      videoRefs.current[activeVideo].pause();
    }
    setActiveVideo(index);
  };

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => handleVideoPlay(index),
  };

  const VideoCard = ({ achievement, index }) => {
    const handleClick = () => {
      handleVideoPlay(index);
      if (videoRefs.current[index]) {
        videoRefs.current[index].play();
      }
    };
  
    return (
      <Card sx={{ height: '60vh', position: 'relative' }}>
        <CardMedia
          component="video"
          height="100%"
          image={achievement.video}
          ref={(el) => (videoRefs.current[index] = el)}
          onClick={handleClick} // Ensure this is the default way to handle click on the video element itself.
          sx={{ objectFit: 'cover' }}
        />
        {activeVideo !== index && (
          <>
            <CardMedia
              component="img"
              height="100%"
              image={achievement.thumbnail}
              alt={achievement.title}
              sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            />
            <Button
              variant="contained"
              startIcon={<PlayArrowIcon />}
              onClick={handleClick} // Handle play button click by starting the video playback.
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.5)' },
              }}
            >
              Play
            </Button>
          </>
        )}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.54)',
            color: 'white',
            padding: '10px',
          }}
        >
          <Typography variant="h6">{achievement.title}</Typography>
        </Box>
      </Card>
    );
  };
  

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography
        variant="h4"
        component="h2"
        align="center"
        sx={{
          fontWeight: 'bold',
          mb: 4,
          textTransform: 'uppercase',
        }}
      >
        Glimpse of Achievements
      </Typography>
      {isMobile ? (
        <Slider {...sliderSettings}>
          {achievements.map((achievement, index) => (
            <VideoCard key={index} achievement={achievement} index={index} />
          ))}
        </Slider>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {achievements.map((achievement, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <VideoCard achievement={achievement} index={index} />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default GlimpseOfAchievements;