import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  TextField,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HelpCenter = () => {
  const [expanded, setExpanded] = useState(false);
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleContactFormChange = (event) => {
    const { name, value } = event.target;
    setContactForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here (e.g., send to API)
    console.log('Form submitted:', contactForm);
    // Reset form after submission
    setContactForm({ name: '', email: '', message: '' });
  };

  const faqs = [
    { question: "How do I place an order?", answer: "You can place an order by..." },
    { question: "What are your shipping options?", answer: "We offer the following shipping options..." },
    { question: "How can I track my order?", answer: "You can track your order by..." },
    // Add more FAQs as needed
  ];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>Help Center</Typography>
      
      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>Frequently Asked Questions</Typography>
      {faqs.map((faq, index) => (
        <Accordion 
          key={index} 
          expanded={expanded === `panel${index}`} 
          onChange={handleChange(`panel${index}`)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}

      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>Contact Us</Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Your Name"
          name="name"
          value={contactForm.name}
          onChange={handleContactFormChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={contactForm.email}
          onChange={handleContactFormChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="message"
          label="Your Message"
          id="message"
          multiline
          rows={4}
          value={contactForm.message}
          onChange={handleContactFormChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Send Message
        </Button>
      </Box>
    </Box>
  );
};

export default HelpCenter;