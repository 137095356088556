// ProductCard.js
import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  IconButton,
  Rating,
  Box,
  styled,
  Tooltip,
  Chip,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { useCart } from "../CartContext";


const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: "16px",
  backgroundColor: "#FFFFFF",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 12px rgba(0,0,0,0.15)",
  },
}));

const CardMediaWrapper = styled(Box)({
  position: "relative",
  paddingTop: "75%", // 4:3 Aspect Ratio
});

const StyledCardMedia = styled(CardMedia)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const FavoriteButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "8px",
  right: "8px",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
}));

const PriceChip = styled(Chip)(({ theme }) => ({
  position: "absolute",
  bottom: "8px",
  right: "8px",
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const CartButton = styled(Button)(({ theme, incart }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: incart === 'true' ? theme.palette.error.main : theme.palette.primary.main,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: incart === 'true' ? theme.palette.error.dark : theme.palette.primary.dark,
  },
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
}));


const PriceBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "8px",
  right: "8px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
}));

const RegularPrice = styled(Typography)(({ theme, onsale }) => ({
  textDecoration: onsale === 'true' ? 'line-through' : 'none',
  color: onsale === 'true' ? theme.palette.text.secondary : theme.palette.primary.main,
  fontWeight: 'bold',
}));

const SalePrice = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: 'bold',
}));

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + '...';
};

const ProductCard = ({ id, name, image, price, salePrice, rating, description, onProductClick }) => {
  const { addToCart, removeFromCart, cartItems } = useCart();
  const [isInCart, setIsInCart] = useState(false);

  useEffect(() => {
    const checkIfInCart = () => {
      const found = cartItems.some(item => item.id === parseInt(id, 10));
      setIsInCart(found);
    };
    checkIfInCart();
  }, [cartItems, id]);

  const handleCartAction = (e) => {
    e.stopPropagation();
    if (isInCart) {
      removeFromCart(parseInt(id, 10));
    } else {
      addToCart({ id: parseInt(id, 10), name, image, price: salePrice || price, description, quantity: 1 });
    }
  };

  const truncatedName = truncateText(name, 20);
  const truncatedDescription = truncateText(description, 60);

  return (
    <StyledCard sx={{bgcolor:'#F9F4ED'}} onClick={() => onProductClick(id, name)}>
      <CardMediaWrapper>
        <StyledCardMedia
          component="img"
          image={image}
          alt={name}
        />
        <FavoriteButton size="small">
          <FavoriteBorderIcon />
        </FavoriteButton>
        <PriceBox>
          <RegularPrice variant="body2" onsale={(salePrice !== null).toString()}>
            Rs.{price.toFixed(2)}
          </RegularPrice>
          {salePrice && (
            <SalePrice variant="body2">
              Rs.{salePrice.toFixed(2)}
            </SalePrice>
          )}
        </PriceBox>
      </CardMediaWrapper>
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold", cursor: "pointer" }}>
            {truncatedName}
          </Typography>
          <Tooltip title={description}>
            <Typography variant="body2" sx={{ mb: 2, height: '3em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {truncatedDescription}
            </Typography>
          </Tooltip>
        </Box>
        <Box>
          <Rating name="read-only" value={rating} readOnly size="small" sx={{ mb: 1 }} />
          <CartButton
            variant="contained"
            fullWidth
            startIcon={isInCart ? <RemoveShoppingCartIcon /> : <AddShoppingCartIcon />}
            onClick={handleCartAction}
            incart={isInCart.toString()}
          >
            {isInCart ? "Remove from Cart" : "Add to Cart"}
          </CartButton>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default ProductCard;