import React from 'react'
import { Box, Typography, Container, AppBar, Toolbar, Button, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';

const SectionTop = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
    

      <Box sx={{ bgcolor: 'gold', position: 'relative', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h2" component="h1" sx={{ fontWeight: 'bold' }}>
          ABOUT US
        </Typography>
        {/* <Box component="img" src="/img/image-35.png" alt="Product splash" sx={{ position: 'absolute', right: 0, bottom: 0, maxHeight: '100%' }} /> */}
      </Box>

      <Container sx={{ mt: 4, mb: 8 }}>
        <Typography sx={{fontSize:'24px'}} paragraph>
          <strong>HAMZA</strong> stands out by offering superior products at affordable prices while prioritizing taste and nutritional value. Each products is meticulously formulated to provide an optimal blend of nutrients, catering to individuals seeking effective post-workout or training fuel. HMZA products are packed by personalized doctors and rigorously tested in multiple laboratories. We've customized our own formulas to ensure the best nutritional value for our customer .
        </Typography>
        <Typography  sx={{fontSize:'24px'}} paragraph>
          The brand's unique selling proposition lies in its commitment to delivering clean , tasty value for money products , thus setting a new standard in the industry. With HAMZA , customer can expect a wide variety of option that not only taste great but also support their fitness and wellness goals.
        </Typography>
      </Container>
    </Box>
  )
}

export default SectionTop