import React, { useState, useEffect, useCallback } from "react";
import { Box, IconButton } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Carousel = ({ items, interval = 5000 }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = items.length;

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  }, [maxSteps]);

  const handleBack = useCallback(() => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
    );
  }, [maxSteps]);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [handleNext, interval]);

  return (
    <Box sx={{ position: "relative", width: "100%", maxHeight: '60vh', overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          transition: "transform 0.5s",
          transform: `translateX(-${activeStep * 100}%)`,
        }}
      >
        {items.map((item, index) => (
          <Link
            key={index}
            to={item.link}
            style={{ flexShrink: 0, width: "100%" }}
          >
            <img
              src={item.image}
              alt={item.alt}
              style={{ width: "100%", height: "100%", objectFit: "cover", display: "block" }}
            />
          </Link>
        ))}
      </Box>
      <IconButton
        onClick={handleBack}
        sx={{
          position: "absolute",
          left: 8,
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          },
        }}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          },
        }}
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
};

export default Carousel;