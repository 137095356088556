import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Components/Header';
import Details from '../Components/Product/Details';
import Reviews from '../Components/Product/Reviews';
import Recommended from '../Components/Product/Recommended';
import RecommendedProduct from '../Components/RecommendedProduct';
import InfoBar from '../Components/Product/InfoBar';
import Footer from '../Components/Footer';

const Product = () => {
  const { id, name } = useParams();

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // You can still use id and name in your component if needed
  console.log('Product ID:', id);
  console.log('Product Name:', name);

  return (
    <div>
      <Header />
      <Details id={id} name={name} />
      <Reviews />
      {/* <Recommended /> */}
      <RecommendedProduct/>
      <InfoBar />
      <Footer />
    </div>
  );
};

export default Product;