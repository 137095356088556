import React, { useRef, useState, useEffect } from "react";
import { Typography, Container, Box, Card, CardMedia } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const CertificateCard = ({ ribbonSrc, certificateSrc }) => (
  <Card sx={{ 
    position: 'relative', 
    minWidth: { xs: '85%', sm: '45%', md: '30%' },
    height: '100%', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    mr: 2,
    flexShrink: 0,
    scrollSnapAlign: 'start',
  }}>
    <CardMedia
      component="img"
      src={ribbonSrc}
      alt="Ribbon"
      sx={{ width: '30%', position: 'absolute', top: 0, left: 0, zIndex: 1 }}
    />
    <CardMedia
      component="img"
      src={certificateSrc}
      alt="Certificate"
      sx={{ width: '100%', height: 'auto' }}
    />
  </Card>
);

const Certifications = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const certificates = [
    { ribbon: "img/reward-with-a-ribbon-2.png", certificate: "img/image-48-1.png" },
    { ribbon: "img/reward-with-a-ribbon-2.png", certificate: "img/image-48-1.png" },
    { ribbon: "img/reward-with-a-ribbon-2.png", certificate: "img/image-48-1.png" },
  ];

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const distance = (x - startX) * 2;
    scrollContainerRef.current.scrollLeft = scrollLeft - distance;
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('mouseup', handleMouseUp);
      container.addEventListener('mouseleave', handleMouseUp);
    }
    return () => {
      if (container) {
        container.removeEventListener('mouseup', handleMouseUp);
        container.removeEventListener('mouseleave', handleMouseUp);
      }
    };
  }, []);

  return (
    <Container sx={{ my: 4, overflow: 'hidden' }}>
      <Typography
        variant={isMobile ? "h5" : "h4"}
        component="h2"
        align="center"
        sx={{ mb: { xs: 3, md: 4 }, fontWeight: "bold" }}
      >
        CERTIFICATION
      </Typography>
      <Box
        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          overflowY: 'hidden',
          WebkitOverflowScrolling: 'touch',
          scrollSnapType: 'x mandatory',
          pb: 2,
          cursor: isDragging ? 'grabbing' : 'grab',
          '&::-webkit-scrollbar': {
            height: '6px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0,0,0,0.1)',
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '3px',
          },
        }}
      >
        {certificates.map((cert, index) => (
          <CertificateCard key={index} ribbonSrc={cert.ribbon} certificateSrc={cert.certificate} />
        ))}
      </Box>
    </Container>
  );
};

export default Certifications;