// ProductPage.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Rating,
  Stack,
  IconButton,
  Grid,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import axios from "axios";
import { useCart } from "../../CartContext";
import { useParams } from "react-router-dom";

const ProductImage = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
});

const ThumbnailImage = styled('img')({
  width: 80,
  height: 80,
  objectFit: 'cover',
  cursor: 'pointer',
  borderRadius: '8px',
});

const CertificateBox = styled(Box)({
  border: '1px solid #39AFD3',
  borderRadius: '8px',
  width: '100%',
  height: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ProductPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const { cartItems, addToCart, updateQuantity } = useCart();
  const [cartItem, setCartItem] = useState(null);
  const [quantityToUpdate, setQuantityToUpdate] = useState(1);
  const [isUpdateButtonEnabled, setIsUpdateButtonEnabled] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`https://hmzafuel.com/APIs/product.php?product_id=${id}`);
        setProduct(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  useEffect(() => {
    if (product) {
      const item = cartItems.find(item => item.id === parseInt(product.id, 10));
      console.log("Found cart item:", item);
      setCartItem(item);
      setQuantityToUpdate(item ? item.quantity : 1);
      setIsUpdateButtonEnabled(false);
    }
  }, [product, cartItems, id]);

  const handleAddToCart = async () => {
    setIsAddingToCart(true);
    try {
      if (cartItem) {
        await updateQuantity(cartItem.id, quantityToUpdate);
      } else {
        const newItem = { 
          ...product, 
          id: parseInt(product.id, 10), 
          quantity: quantityToUpdate
        };
        await addToCart(newItem);
      }
      setIsAddingToCart(false);
      setIsUpdateButtonEnabled(false);
      // Force re-render
      setProduct({...product});
    } catch (error) {
      console.error("Error in handleAddToCart:", error);
      setIsAddingToCart(false);
    }
  };

  const handleQuantityChange = (newQuantity) => {
    setQuantityToUpdate(newQuantity);
    setIsUpdateButtonEnabled(true);
  };

  const handleUpdateCart = async () => {
    try {
      await updateQuantity(cartItem.id, quantityToUpdate);
      setIsUpdateButtonEnabled(false);
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">Failed to load product details.</Typography>
      </Box>
    );
  }

  if (!product) {
    return null;
  }

  const images = product.images && product.images.length > 0 
    ? product.images.map(img => img.image_path) 
    : ["/img/image-38-5.png"];

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: 'auto' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box sx={{ bgcolor: '#F6DC91', p: 2, borderRadius: '8px', mb: 2 }}>
            <ProductImage src={images[selectedImage]} alt={product.name} />
          </Box>
          <Stack direction="row" spacing={2} justifyContent="center">
            {images.map((img, index) => (
              <ThumbnailImage
                key={index}
                src={img}
                onClick={() => setSelectedImage(index)}
                sx={{ border: index === selectedImage ? '2px solid #1976d2' : 'none' }}
              />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {product.name}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {product.description}
          </Typography>
          <Typography variant="h5" color="error" gutterBottom>
            Rs.{product.price}
          </Typography>
          <Rating value={5} readOnly size="small" />
          <Typography variant="body2" color="text.secondary" mt={1} mb={2}>
            Net Weight: {product.weight} grams
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center" mt={3}>
            <Box sx={{ border: '1px solid #ccc', borderRadius: '20px', display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={() => handleQuantityChange(Math.max(1, quantityToUpdate - 1))}>
                <RemoveIcon />
              </IconButton>
              <Typography sx={{ px: 2 }}>{quantityToUpdate}</Typography>
              <IconButton onClick={() => handleQuantityChange(quantityToUpdate + 1)}>
                <AddIcon />
              </IconButton>
            </Box>
            <Button
              variant="contained"
              onClick={cartItem ? (isUpdateButtonEnabled ? handleUpdateCart : handleAddToCart) : handleAddToCart}
              disabled={isAddingToCart}
              sx={{
                bgcolor: cartItem ? "var(--Final-red, #F00)" : "#FFC107",
                color: "white",
                "&:hover": { bgcolor: cartItem ? "var(--Final-red, #F00)" : "#FFC107" },
                flexGrow: 1,
              }}
            >
              {isAddingToCart ? (
                <CircularProgress size={24} />
              ) : cartItem ? (
                isUpdateButtonEnabled ? "Update Cart" : `In Cart (${cartItem.quantity})`
              ) : (
                "Add To Cart"
              )}
            </Button>
          </Stack>
          
          <Grid container spacing={2} mt={3} mb={3}>
            {[1, 2, 3, 4].map((cert) => (
              <Grid item xs={3} key={cert}>
                <CertificateBox />
              </Grid>
            ))}
          </Grid>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Ingredients</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{product.ingredients || "Information not available"}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Key Points</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{product.key_points || "Information not available"}</Typography>
            </AccordionDetails>
          </Accordion>

          <Box mt={3}>
            <Typography variant="subtitle1" gutterBottom>
              Share
            </Typography>
            <Stack direction="row" spacing={2}>
              <IconButton>
                <WhatsAppIcon />
              </IconButton>
              <IconButton>
                <FacebookIcon />
              </IconButton>
              <IconButton>
                <InstagramIcon />
              </IconButton>
              <IconButton>
                <TwitterIcon />
              </IconButton>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductPage;