import React from "react";
import { Typography, Box, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../Components/Header";
import Hero from "../Components/Hero";
import ShopByCategory from "../Components/Categories";
import ProductList from "../Components/ProductList";
import OurFounder from "../Components/OurFounder";
import ScrollableVideoCards from "../Components/NewLaunchReels";
import Certifications from "../Components/Certifications";
import AthleteSection from "../Components/AthleteSection";
import Reviews from "../Components/Reviews";
import RecommendedProduct from "../Components/RecommendedProduct";
import Footer from "../Components/Footer";

const HomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <div className="Homecontainer">
      <Header className="frame" />
      <Hero />
      
      {/* Responsive "Elevate Your Strength" section */}
      <Box 
        component="section" 
        className="small-inmiddle-message"
        sx={{
          py: { xs: 3, sm: 4, md: 5 },
          my: { xs: 3, sm: 4, md: 5 },
        }}
      >
        <Container>
          <Typography
            variant="h2"
            component="h2"
            align="center"
            sx={{
              fontWeight: "bold",
              color: "#333",
              textTransform: "uppercase",
              letterSpacing: { xs: 1, sm: 2 },
              lineHeight: 1.2,
              fontSize: {
                xs: '1rem',     // 1rem on mobile
                sm: '2rem',     // 2rem on tablet (optional)
                md: '3rem',     // 3rem on desktop
              },
            }}
          >
            "Elevate Your Strength, Transform Your Life"
          </Typography>
        </Container>
      </Box>

      <ShopByCategory />
      <ProductList />
      <OurFounder />
      <ScrollableVideoCards />
      <Certifications />
      <AthleteSection />
      <Reviews />
      <RecommendedProduct />
      <Footer />
    </div>
  );
};

export default HomePage;