import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
} from "@mui/material";

const AthleteCard = ({ imageUrl, name, profession }) => (
  <Card sx={{ maxWidth: 345, m: 2, bgcolor:'#FFECD0' }}>
    <CardMedia component="img" height="200" image={imageUrl} alt={name} />
    <CardContent>
      <Typography variant="h6" component="div">
        {name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {profession}
      </Typography>
      <Button variant="outlined" sx={{ mt: 2,fontWeight: '700', color:'black', }}>
        Know More
      </Button>
    </CardContent>
  </Card>
);

const AthletesSection = () => {
  const athletes = [
    {
      imageUrl: "/img/image-48-2.png",
      name: "Athlete 1",
      profession: "Sport 1",
    },
    {
      imageUrl: "/img/image-50.png",
      name: "Athlete 2",
      profession: "Sport 2",
    },
    {
      imageUrl: "/img/image-49.png",
      name: "Athlete 3",
      profession: "Sport 3",
    },
    {
      imageUrl: "/img/image-50.png",
      name: "Athlete 1",
      profession: "Sport 1",
    },
    {
      imageUrl: "/img/image-49.png",
      name: "Athlete 2",
      profession: "Sport 2",
    },
    {
      imageUrl: "/img/image-50.png",
      name: "Athlete 3",
      profession: "Sport 3",
    },
    {
      imageUrl: "/img/image-49.png",
      name: "Athlete 1",
      profession: "Sport 1",
    },
    {
      imageUrl: "/img/image-49.png",
      name: "Athlete 2",
      profession: "Sport 2",
    },
    {
      imageUrl: "/img/image-49.png",
      name: "Athlete 3",
      profession: "Sport 3",
    },
  ];

  return (
    <Box sx={{ textAlign: "center", p: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{fontSize: '40px', fontWeight: '700'}}>
        ATHLETES
      </Typography>
      <Typography variant="h5" component="h2" sx={{color:'var(--Final-red, #F00)', fontWeight: '900', fontSize: '34px'}} gutterBottom>
        "UNLEASH YOUR INNER CHAMPION"
      </Typography>
      <Grid container justifyContent="center">
        {athletes.map((athlete, index) => (
          <Grid item key={index}>
            <AthleteCard {...athlete} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AthletesSection;
