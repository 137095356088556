import React, { useRef, useState, useEffect } from "react";
import { Typography, Container, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const AthleteSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const athletes = [
    { image: "/img/image-48-2.png", background: "#B6E2EF" },
    { image: "/img/image-49.png", background: "#F6DC91" },
    { image: "/img/image-50.png", background: "#F6BCEE" },
    { image: "/img/image-49.png", background: "#F6DC91" },
  ];

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const distance = (x - startX) * 2; // Adjust scrolling speed
    scrollContainerRef.current.scrollLeft = scrollLeft - distance;
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('mouseup', handleMouseUp);
      container.addEventListener('mouseleave', handleMouseUp);
    }
    return () => {
      if (container) {
        container.removeEventListener('mouseup', handleMouseUp);
        container.removeEventListener('mouseleave', handleMouseUp);
      }
    };
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 3, sm: 4, md: 5 }, overflow: 'hidden' }}>
      <Typography
        variant={isMobile ? "h5" : "h4"}
        component="h2"
        align="center"
        sx={{ mb: { xs: 3, md: 4 }, fontWeight: "bold" }}
      >
        ATHLETES CHOICE
      </Typography>
      <Box
        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          overflowY: 'hidden',
          WebkitOverflowScrolling: 'touch',
          scrollSnapType: 'x mandatory',
          gap: { xs: 2, sm: 3 },
          pb: 2,
          cursor: isDragging ? 'grabbing' : 'grab',
          '&::-webkit-scrollbar': {
            height: '6px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0,0,0,0.1)',
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '3px',
          },
        }}
      >
        {athletes.map((athlete, index) => (
          <Box
            key={index}
            sx={{
              flexShrink: 0,
              scrollSnapAlign: 'start',
              background: athlete.background,
              width: { xs: '80vw', sm: 280, md: 300 },
              height: { xs: '80vw', sm: 280, md: 300 },
              maxWidth: { xs: 250, sm: 280, md: 300 },
              maxHeight: { xs: 250, sm: 280, md: 300 },
              borderRadius: "15px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              userSelect: 'none', // Prevent text selection during drag
            }}
          >
            <img
              src={athlete.image}
              alt={`Athlete ${index + 1}`}
              style={{ 
                maxWidth: "100%", 
                maxHeight: "100%", 
                objectFit: "cover",
                pointerEvents: "none",
                userDrag: 'none',
                WebkitUserDrag: 'none',
              }}
            />
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default AthleteSection;