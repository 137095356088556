import { createTheme } from '@mui/material/styles';
import { width } from '@mui/system';

const theme = createTheme({
  typography: {
    fontFamily: "Blender pro, Arial, Helvetica Neue, Helvetica, sans-serif",
  },
  // components: {
  //   MuiContainer: {
  //     styleOverrides: {
  //       root: {
  //         width: '100% !important', // Ensures the Container takes full width
  //         maxWidth: '100% !important', // Removes max-width constraints
         
  //       },
  //     },
  //   },
  //   Container:{
  //     width:'100%'
  //   }
  // },
});

export default theme;
