import React from 'react';
import { Box, Typography, Container, Grid, Paper, Button, Card, CardContent, CardMedia } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import GlimpseOfAchievements from './GlimpseOfAchievements';

const AboutFounder = () => {
  const founderAttributes = [
    { title: "THREE-TIME NATIONAL JUDO CHAMPION" },
    { title: "PASSIONATE ABOUT FITNESS" },
    { title: "COMBAT SPORTS ATHLETE" }
  ];

 

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              borderBottom: '2px solid #000',
              pb: 1,
              display: 'inline-block',
              mb: 4,
            }}
          >
            About Founder
          </Typography>
          <Typography variant="body1" paragraph>
            HMZA is a brand founded by Hamza Khan, a three-time National judo champion and accomplished combat sports athlete. With over a decade of experience in practicing Judo and various martial arts disciplines, Hamza has earned multiple medals in national championships.
          </Typography>
          <Typography variant="body1" paragraph>
            Drawing from his extensive journey in martial arts, Hamza envisioned creating his own nutrition brand. HMZA aims to revolutionize the nation's nutrition landscape by introducing high-quality products such as:
          </Typography>
          <ul>
            <li>Protein powder</li>
            <li>BCAAs</li>
            <li>Pre-Workouts</li>
            <li>Protein bars</li>
            <li>Peanut butter</li>
            <li>Oats and Mueslis</li>
            <li>Various Vitamins and Supplements</li>
          </ul>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              bgcolor: '#f5f5f5',
              height: '100%',
              minHeight: 300,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              component="img"
              src="/img/image-43.png"
              alt="Hamza Khan, Founder of HMZA"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
            <Button
              variant="contained"
              startIcon={<PersonIcon />}
              sx={{
                position: 'absolute',
                bottom: 16,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              Meet Hamza Khan
            </Button>
          </Paper>
        </Grid>
      </Grid>

      {/* Founder Highlights Section */}
      <Box sx={{ mt: 8 }}>
        <Grid container spacing={3} justifyContent="center">
          {founderAttributes.map((attribute, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Card sx={{ height: '100%', backgroundColor: '#fff9c4' }}>
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    align="center"
                    sx={{
                      fontWeight: 'bold',
                      color: 'red',
                    }}
                  >
                    {attribute.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Glimpse of Achievements Section */}
      <GlimpseOfAchievements/>
    </Container>
  );
};

export default AboutFounder;